<template>
  <div class="footer_contain">
    <div class="footer align-top">
      <div class="footer_item">
        <img :src="require('@/assets/logo.png')" fit="cover" class="pointer logo" alt="" />
        <div class="title">
          {{ $t('footAll.rmlb') }}
        </div>
        <div class="flex-wrap2">
          <div class="content pointer m_r24" v-for="(item, index) in goodsCate" :key="'goodsCate_' + index"
            style="margin-top:0">
            {{ $i18n.locale == 'en' ? item.cate_name_en
          : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}
          </div>
        </div>
      </div>
      <div class="footer_item">
        <div class="title">
          {{ $t('footAll.fwzc') }}
        </div>
        <div class="content pointer" @click="fnFwb(1)">
          {{ $t('footAll.sjbhzc') }}
        </div>
        <div class="content pointer" @click="fnFwb(2)">
          {{ $t('footAll.yszc') }}
        </div>
      </div>
      <div class="footer_item">
        <div class="title">
          {{ $t('footAll.gywm') }}
        </div>
        <div class="content pointer" @click="fnFwb(3)">
          {{ $t('footAll.gsjs') }}
        </div>
        <div class="content pointer" @click="fnFwb(4)">
          {{ $t('footAll.lxwm') }}
        </div>
      </div>
      <div class="footer_item">
        <div class="title">
          {{ $t('footAll.dz') }}
        </div>
        <div class="content">
          {{ $t('footAll.zb') }} - {{ $i18n.locale == 'en' ? addressZ.content_en
          : $i18n.locale == 'es' ? addressZ.content_es : addressZ.content_tp }}
        </div>
        <div class="content">
          {{ $i18n.locale == 'en' ? addressX.content_en
          : $i18n.locale == 'es' ? addressX.content_es : addressX.content_tp }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firstGoodsCate, getConfig } from '../api'
export default {
  data() {
    return {
      goodsCate: [],
      addressZ: {},
      addressX: {},
    }
  },
  methods: {
    initFirstGoodsCate() {
      let params = {
        type: 2
      }
      firstGoodsCate(params).then((data) => {
        if (data.status == 200) {
          this.goodsCate = data.msg;
        }
      })
    },
    fnAddress(type) {
      let params = {
        type: type,
      }
      getConfig(params).then((data) => {
        if (data.status == 200) {
          if (type == 5) {
            this.addressZ = data.msg
          } else if (type == 6) {
            this.addressX = data.msg
          }
        }
      })
    },
    // fnAboutUsDetail(about_id) {
    //   if (this.$router.history.current.name == 'About') {
    //     this.$bus.$emit('footer', 2, about_id)
    //   }
    //   this.$router.push({
    //     name: 'About',
    //     params: {
    //       id: 2 + '-' + about_id + '.html',
    //     },
    //   })
    //   // this.dialogVisible = true
    //   // this.type = 2
    //   // let params = { about_id: about_id }
    //   // aboutUsDetail(params).then((data) => {
    //   //   if (data.status == 200) {
    //   //     this.fwb = data.msg
    //   //   }
    //   // })
    // },
  },
  mounted() {
    this.initFirstGoodsCate()
    this.fnAddress(5)
    this.fnAddress(6)
  },
}
</script>

<style scoped>
.logo {
  width: 274px;
  height: 67px;
  margin-bottom: 24px;
}

.footer_contain {
  background: #008DD1;
  box-sizing: border-box;
  padding: 50px 0;
}

.footer {
  width: 1200px;
  margin: auto;
}

.footer_item {
  width: 21.3%;
}

.footer_item:first-child {
  width: 36%;
}


.title {
  font-size: 22px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 30px;
  margin-bottom: 13px;
}

.content {
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  margin-top: 10px;
}
</style>
