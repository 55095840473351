<template>
    <div>
        <el-pagination @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="pageSizes" :page-size="limit" layout="prev, pager, next" :total="totalNum">
        </el-pagination>
    </div>
</template>

<script>
export default {
    props: {
        limit: {
            type: Number
        },
        totalNum: {
            type: Number
        },
    },
    data() {
        return {
            currentPage: 1,
            pageSizes: []
        }
    },
    methods: {
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.$emit('onPage', val);
        }
    }
}
</script>

<style scoped>
::v-deep .el-pager li {
    padding: 0 16px;
    background: rgba(0, 0, 0, 0);
    font-size: 22px;
    min-width: 35.5px;
    height: 38px;
    line-height: 38px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 400;
    color: #333;
}

/* :v-deep .el-pager li.active {
    color: #ff0000;
    cursor: default;
    border-bottom: 1px solid #2A76A8;
} */

::v-deep .el-pagination .btn-next .el-icon,
::v-deep .el-pagination .btn-prev .el-icon {
    display: block;
    font-size: 20px;
    font-weight: 700;
}

::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
    background: center center no-repeat rgba(0, 0, 0, 0);
    background-size: 22px;
    cursor: pointer;
    margin: 0;
    color: #666;
}

::v-deep .el-pagination button{
    height: 38px;
    line-height: 38px;
}
</style>
