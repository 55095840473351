<template>
  <div>
    <Header />
    <div class="bread align-start font-333-22-500">
      <div v-show="fCate" style="font-size: 16px;" :class="{ 'color-2A76A8': !sCate }">{{ fCate_name }}</div>
      <img v-show="sCate" :src="require('@/assets/swipe_jt_r2.png')" alt="" style="width:22px;height:22px;margin:0 20px;">
      <div v-show="sCate" style="font-size: 16px;" :class="{ 'color-2A76A8': !tCate }">{{ sCate_name }}</div>
      <img v-show="tCate" :src="require('@/assets/swipe_jt_r2.png')" alt="" style="width:22px;height:22px;margin:0 20px;">
      <div v-show="tCate" class="color-2A76A8">{{ tCate_name }}</div>
    </div>
    <div class="flex-wrap box">
      <div class="left1">
        <div class="font-018ECF-22-500 align-center h60 bg-F4F4F4" style="height: 40px;">{{ $t('cateObj.lb') }}</div>
        <div v-for="(item, index) in goodsCate" :key="'goodsCate_' + item.cate_id" style="padding:0 16px 0 32px;margin-top: 12px;width: 300px;">
          <div class="align-between pointer" @click="fnCate(item.cate_id, $i18n.locale =='en' ? item.cate_name_en: $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp)">
            <div class="font-333-22" style="font-size: 18px;"  :class="{ 'font-333-22-500': fCate == item.cate_id && sCate, 'font-018ECF-22-500': fCate == item.cate_id && !sCate }">
              {{ $i18n.locale =='en' ? item.cate_name_en: $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}</div> 
              <img :src="require('@/assets/' + (fCate == item.cate_id ? 'jian' : 'jia') + '.png')" alt="" class="jia-icon">
          </div>
          <div v-show="fCate == item.cate_id" v-for="(item1, index) in item.secondGoodsCate" :key="'goodsCate_' + item1.cate_id" style="padding-left: 24px;margin-top: 20px;">
            <div class="font-333-22 pointer" style="font-size: 18px;" :class="{ 'font-333-22-500': sCate == item1.cate_id && tCate, 'font-018ECF-22-500': sCate == item1.cate_id && !tCate }" @click="fnCate(item.cate_id, $i18n.locale =='en' ? item.cate_name_en: $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp, item1.cate_id, $i18n.locale =='en' ? item1.cate_name_en: $i18n.locale == 'es' ? item1.cate_name_es : item1.cate_name_tp)"> 
                {{$i18n.locale == 'en' ? item1.cate_name_en : $i18n.locale == 'es' ? item1.cate_name_es :item1.cate_name_tp }}
            </div>
            <div class="font-666-22" v-show="sCate == item1.cate_id" v-for="(item2, index) in item1.thirdGoodsCate" style="padding-left: 24px;margin-top: 14px;" :key="'goodsCate_' + item2.cate_id">
              <div :class="{ 'font-018ECF-22-500': tCate == item2.cate_id }" class="pointer" @click="fnCate(item.cate_id, $i18n.locale =='en' ?item.cate_name_en: $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp, item1.cate_id, $i18n.locale =='en' ?item1.cate_name_en: $i18n.locale == 'es' ? item1.cate_name_es : item1.cate_name_tp, item2.cate_id, $i18n.locale =='en' ?item2.cate_name_en: $i18n.locale == 'es' ? item2.cate_name_es : item2.cate_name_tp)">
                {{ $i18n.locale == 'en' ? item2.cate_name_en: $i18n.locale == 'es' ? item2.cate_name_es : item2.cate_name_tp }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-con">
        <div class=" h60 font-333-22" style="display: flex;justify-content: flex-start;" >
          <div class="font-018ECF-24-500 m_r60" style="font-size: 20px;">{{ $t('cateObj.sx') }}</div>
          <div class=" m_r40"  style="font-size: 18px;display: flex;justify-content: flex-start;" v-for="(item, index) in goodsAttrObj" :key="'goodsAttr_' + item.attr_id + '_' + index">
            <input type="checkbox" name="goodsAttr" :data-idd="item.attr_id" @click="fnSelectAttr(item.attr_id)" />
            <div>{{ $i18n.locale == 'en' ? item.attr_name_en
                : $i18n.locale == 'es' ? item.attr_name_es : item.attr_name_tp }}</div>
          </div>
        </div>
        <div class="four-out">
          <div class="four center  relative" v-for="(item, index) in goods" :key="'goods_' + item.goods_id">
            <!-- <el-image style="width: 28px; height: 28px; margin-right: 16px" class="ppp" :src="item.isCollect == 1
                    ? require('@/assets/star1.png')
                    : require('@/assets/star2.png')
                    " fit="cover"></el-image> -->
                
            <!-- <input type="checkbox" name="collect" :checked="item.isCollect == 1 ? true : false" disabled /> -->
            <div class="fourImg_out align-center">
              <img :src="item.goods_thumb" class="fourImg" alt="">
            </div>
            <div style="padding: 0 20px;margin-top: 30px;">
              <div class="font-666-28 aui-ellipsis-2 h56" style="font-size: 18px;">
                {{ $i18n.locale == 'en' ? item.goods_name_en
                : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}
              </div>
              <div class="center font-333-20-500" style="font-size: 16px;margin-top: 6px;">
                <!-- <span class="through color-999 normal">$ {{ item.goods_price }}</span> -->
                $ {{ item.goods_cu_price }}
              </div>
            </div>
            <div class="btn1 align-center m_t12 margin pointer" @click="fnGoodsDetails(item.goods_id)">{{
                $t('mainCK')
            }}</div>
            <div class="center font-666-18 product-intro">
              {{ $i18n.locale == 'en' ? item.goods_intro_en
                : $i18n.locale == 'es' ? item.goods_intro_es : item.goods_intro_tp }}
            </div>
          </div>
        </div>
        <div class="align-center m_t60" v-show="total > 0">
          <Page :limit="limit" :totalNum="total" @onPage="changePage" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Page from '@/components/Page.vue'
import { limit, goodsAttr, goodsList, goodsCateList } from '../api'
export default {
  components: {
    Header,
    Footer,
    Page
  },
  data() {
    return {
      page: 1,
      limit: limit,
      total: 0,
      goodsCate: [],
      goodsAttrObj: [],
      fCate: '',
      sCate: '',
      tCate: '',
      attr: '',
      fCate_name: '',
      sCate_name: '',
      tCate_name: '',
      goods: [],
      keyword: this.$route.query.keyword,
    }
  },
  created() {
    this.$bus.$off('keyword')
    this.$bus.$off('cate')
    this.$bus.$on('keyword', (val) => {
      this.keyword = val
      this.$router.push({
        path: 'cate',
        query: {
          keyword: this.keyword
        }
      })
      this.initGoodsList()
    })
    this.$bus.$on('cate', (val) => {
      this.fCate = val.id1 || ''
      this.sCate = val.id2 || ''
      this.tCate = val.id3 || ''
      this.fCate_name = val.id1_name || ''
      this.sCate_name = val.id2_name || ''
      this.tCate_name = val.id3_name || ''
      this.initGoodsList()
    })
  },
  mounted() {
    this.keyword = this.$route.query.keyword || ''
    if (this.keyword) {
      this.$bus.$emit('keywordGet', this.keyword)
    }
    this.fCate = this.$route.params.id1 || ''
    this.sCate = this.$route.params.id2 || ''
    this.tCate = this.$route.params.id3 || ''
    this.fCate_name = this.$route.params.id1_name || ''
    this.sCate_name = this.$route.params.id2_name || ''
    this.tCate_name = this.$route.params.id3_name || ''
    this.initCate()
    this.initGoodsAttr()
    this.initGoodsList()
    
  },
  methods: {
    fnSelectAttr(idd) {
      // this.attr = idd
      let ids = []
      let allSx = document.querySelectorAll("input[name='goodsAttr']:checked")
      allSx.forEach((element) => {
        ids.push(element.dataset.idd)
      })
      this.attr = ids.join(',')
      this.page = 1
      this.initGoodsList()
    },
    fnCate(id1, id1_name, id2, id2_name, id3, id3_name) {
      this.page = 1
      if (id1 && !id2) {
        if (this.fCate == id1) {
          this.fCate = ''
          this.sCate = ''
          this.tCate = ''
          this.initGoodsList()
          return
        }
      }
      this.fCate = id1 || ''
      this.sCate = id2 || ''
      this.tCate = id3 || ''
      this.fCate_name = id1_name || ''
      this.sCate_name = id2_name || ''
      this.tCate_name = id3_name || ''
      this.initGoodsList()
    },
    initCate() {
      let params = {}
      goodsCateList(params).then((data) => {
        if (data.status == 200) {
          this.goodsCate = data.msg
        }
      })
    },
    initGoodsList() {
      let params = {
        page: this.page,
        keyword: this.keyword,
        fCate: this.fCate,
        sCate: this.sCate,
        tCate: this.tCate,
        attr: this.attr,
      }
      goodsList(params).then((data) => {
        if (data.status == 200) {
          this.goods = data.msg
          this.total = data.count
        }
      })
    },
    initGoodsAttr() {
      let params = {}
      goodsAttr(params).then((data) => {
        if (data.status == 200) {
          this.goodsAttrObj = data.msg
        }
      })
    },
    changePage(childrenData) {
      this.page = childrenData;
      this.initGoodsList();
    }
  }

}
</script>


<style scoped>
.jia-icon {
  width: 24px;
  height: 24px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #979797;
  border-radius: 2px;
}

input[type="checkbox"]:checked {
  border: 2px solid #018ecf;
  background: url("@/assets/checked1.png") no-repeat center center;
  background-size: 18px 18px;
}
input[name="collect"] {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;
}
::v-deep .el-tree-node__label {
  font-size: 22px;
}

::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
  display: none;
}

::v-deep .el-tree > .el-tree-node > .el-tree-node__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 24px;
  font-size: 22px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  height: auto;
}

::v-deep .el-tree > .el-tree-node > .el-tree-node__content:after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url("@/assets/jian.png") no-repeat center center;
  background-size: 24px 24px;
  float: right;
}

::v-deep
  .el-tree
  > .el-tree-node:focus.is-expanded
  > .el-tree-node__content:after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url("@/assets/jia.png") no-repeat center center;
  background-size: 24px 24px;
  float: right;
}

::v-deep .el-tree > .el-tree-node > .el-tree-node__children > .el-tree-node {
  font-size: 22px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  margin-top: 20px;
}

::v-deep
  .el-tree
  > .el-tree-node
  > .el-tree-node__children
  > .el-tree-node
  > .el-tree-node__content,
::v-deep
  .el-tree
  > .el-tree-node
  > .el-tree-node__children
  > .el-tree-node
  > .el-tree-node__children
  > .el-tree-node
  > .el-tree-node__content {
  height: auto;
}

::v-deep
  .el-tree
  > .el-tree-node
  > .el-tree-node__children
  > .el-tree-node
  > .el-tree-node__children
  > .el-tree-node {
  font-size: 22px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  margin-top: 14px;
}

::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: rgba(0, 0, 0, 0);
  font-weight: 500;
  color: #018ecf;
}

/* ::v-deep .el-tree>.el-tree-node:focus>.el-tree-node__content {
    font-weight: 500;
    color: #333;
} */

::v-deep .el-tree-node__content:hover,
::v-deep .el-upload-list__item:hover {
  background-color: rgba(0, 0, 0, 0);
  font-weight: 500;
  color: #018ecf;
}

/* ::v-deep .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content>.el-tree-node__label {
    color: #018ECF !important;
} */

.four-out {
  display: flex;
  flex-wrap: wrap;
  width: 1390px;
}

.four-out .fourImg_out {
  display: block;
  width: 100%;
  height: 230px;
  margin: 0 auto 23px;
  position: relative;
}

.four-out .fourImg_out .fourImg {
  object-fit: cover;
  display: block;
  width: 100%;
  height: fit-content;
  position: absolute;
  margin: 0 auto;
  
  box-sizing: border-box;
  padding: 2px;
  overflow: hidden;
}

.four-out .four {
  margin-right: calc(10% / 4);
  margin-top: 25px;
  position: relative;
  display: inline-block;
  width: 18%;
  height: 420px;
  background: #f8f8f8;
  box-sizing: border-box;
  /* padding-top: 30px; */
  transition: all 0.3s;
  overflow: hidden;
}

.four-out .four:hover {
  box-shadow: 0px 0px 20px 0px rgba(42, 118, 168, 0.2);
}

.four-out .four:nth-child(5n) {
  margin-right: 0;
}

.btn1 {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;
  height: 36px;
  background: #008dd1;
  border-radius: 36px;
  width: 82%;
  transition: all 0.3s;
  margin-bottom: 16px;
}

/* .four:hover .product-intro {
    display: block;
}

.four:hover .fourImg,
.four:hover .btn1 {
    display: none;
} */

.product-intro {
  height: 40%;
  overflow-y: scroll;
  display: none;
  margin-top: 40px;
  padding: 0 20px;
  transition: all 0.3s;
}

.product-intro::-webkit-scrollbar {
  display: none;
}

.collect {
  width: 28px;
  height: 28px;
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 1;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #979797;
  border-radius: 2px;
  margin-right: 16px;
}

input[type="radio"]:checked {
  border: 2px solid #018ecf;
  background: url("@/assets/checked1.png") no-repeat center center;
  background-size: 18px 18px;
}

.left1 {
  width: 21%;
  margin-right: 1%;
}

.box {
  padding: 36px 60px;
  background-color: #fff;
}

.bread {
  height: 44px;
  background: #f8f8f8;
  padding: 0 60px;
}
.color-2A76A8{
  color: #2A76A8;
  font-size: 16px;
}
.ppp{
  position: absolute;
    right: -4px;
    top: 8px;
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    
   
    z-index: 1;
}
</style>