<template>
    <div style="background-color: #FFFFFF;">
        <Header />
        <div style="width: 1200px;;background-color: #fff;margin: 0 auto;">
            <div class="card flex-wrap">
                <div class="left1 relative">
                    <div class="bigDiv">
                        <img :src="goods_images[index]" alt="" style="width: 840px; height:840px" />
                    </div>
                    <div class="swiper-container gallery-top" style="height: 280px;width: 280px;margin-top: 20px;">
                        <div class="swiper-wrapper swiper-wrapper1" >
                            <div class="swiper-slide swiper-no-swiping"   v-for="(item, index1) in goods_images"
                                :key="'goods_images1_' + index1">
                                <div :id="'smDiv' + index1" style="position: relative; height: 280px;">
                                    <img :src="item"  />
                                    <div class="zoom" :id="'zoom' + index1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-container gallery-thumbs">
                        <div class="swiper-wrapper" style="box-sizing: border-box">
                            <div class="swiper-slide" v-for="(item, index2) in goods_images"
                                :key="'goods_images2_' + index2">
                                <img class="pointer kuang1" :src="item" />
                            </div>
                        </div>
                        <div class="swiper-button-next swiper-button-next2 swiper-button-white" style="top: 48px"></div>
                        <div class="swiper-button-prev swiper-button-prev2 swiper-button-white" style="top: 48px"></div>
                    </div>
                    <div class="align-start m_b60" style="margin-top: 12px;">
                <el-image style="width: 28px; height: 28px; margin-right: 16px" @click='(e) => { cancleCollect(e, data.goods_id) }' class="pointer" :src="data.goodsCollect == 1
                    ? require('@/assets/star1.png')
                    : require('@/assets/star2.png')
                    " fit="cover"></el-image>
                <!-- <input type="checkbox" name="collect" class="collect" :checked="data.goodsCollect == 1 ? true : false"
                    @click='(e) => { cancleCollect(e, data.goods_id) }' /> -->
                <div class="font-999-20">{{ data.goodsCollect == 1 ? $t('goodsObj.qxsc') : $t('goodsObj.tjsc') }}</div>
            </div>
                </div>
                <div class="flex-con" style="margin-left: 10px;">
                    <div class="p_b16 b-b-979797">
                        <div class="font-333-24-500 " style="font-size: 20px;margin-top: 20px;">{{ $i18n.locale == 'en' ? data.cateName_en
                            : $i18n.locale == 'es' ? data.cateName_es : data.cateName_tp }}</div>
                        <div class="font-333-30-500 " style="font-size: 24px;margin-top: 14px;line-height: 33px;">{{ $i18n.locale == 'en' ? data.goods_name_en
                            : $i18n.locale == 'es' ? data.goods_name_es : data.goods_name_tp }}</div>
                        <div class="font-018ECF-30-500 m_b16" style="font-size: 24px;margin-top: 14px;">$ {{ goods_cu_price }}</div>
                        <!-- <div class="font-666-16 m_b3">Puntualidad</div> -->
                        <div class="m_b18">{{ $i18n.locale == 'en' ? data.goods_intro_en
                            : $i18n.locale == 'es' ? data.goods_intro_es : data.goods_intro_tp }}</div>
                        <!-- <div class="font-666-16 m_b5">Volumen de pedidos iniciales</div>
                        <div class="font-333-20-500 m_b34">El valor mínimo de este producto se establece en 4 *</div> -->
                        <div class="font-333-20-500" style="font-size: 16px;">{{ $t('goodsObj.xzgg') }}</div>
                    </div>

                    <div class="font-333-18-500 m_t20" style="font-size:16px;">{{ $i18n.locale == 'en' ? data.goods_spec_name_en
                            : $i18n.locale == 'es' ? data.goods_spec_name_es : data.goods_spec_name_tp }}：</div>
                    <div class="flex-wrap2">
                        <div class="m_t14 label1 align-center pointer" style="font-size:16px;" v-for="(item, index) in goods_guige1"
                            @click="selectGuige(1, item.spec_id)" :class="{ active: item.spec_id == spec_id }"
                            :key="'guige1_' + index">
                            {{ item.spec_value || '' }}
                        </div>
                    </div>
                    <div class="font-333-18-500 m_t20">{{ $t('goodsObj.bz') }}：</div>
                    <div class="flex-wrap2">
                        <div class="m_t14 label1 align-center pointer" v-for="(item, index) in goods_guige2"
                            @click="selectGuige(2, item.packaging_id)" :class="{ active: item.packaging_id == packaging_id }"
                            :key="'guige2_' + index">
                            {{ item.packaging_name || '' }}
                        </div>
                    </div>
                </div>
                <div class="m_t24 right1">
                    <div class="font-333-22-500 m_b14" style="font-size: 16px;">{{ $t('goodsObj.sl') }}</div>
                    <el-input-number   v-model="num" @change="handleChange" :min="1"></el-input-number>
                    <!-- <div class="font-333-18-500 m_b30 m_t14">Compra mínima 4*</div> -->
                    <div class="font-333-22-500 m_b14 m_t30" style="font-size: 14px;">{{ $t('goodsObj.zj') }}</div>
                    <div class="font-018ECF-30-500 m_b30" style="font-size: 20px;">$ {{ ((goods_cu_price * num * 100) / 100).toFixed(2) }}
                    </div>
                    <div class="carAll align-center pointer m_b20" style="font-size: 16px;width: 100%;height: 42px;" @click="fnGoodsInCar">
                        {{ $t('goodsObj.jrgwc') }}
                    </div>
                    <div class="carAll pointer align-center" style="font-size: 16px;width: 100%;height: 42px;" @click="fnBuy">
                        {{ $t('goodsObj.ljgm') }}
                    </div>
                </div>
            </div>
           
            <div class="align-start font-333-22 p_b30 b-b-979797">
                <div class="tab-item m_r90 pointer" style="font-size: 16px;" :class="{ active: index == tabIndex }"
                    v-for="(item, index) in tabData" :key="'tabData_' + index" @click="fnTab(index)">
                    {{ item.title }}
                </div>
            </div>
            <div class="fwb" v-show="tabIndex == 0" v-html="$i18n.locale == 'en' ? data.goods_detail_en
                            : $i18n.locale == 'es' ? data.goods_detail_es : data.goods_detail_tp"></div>
            <div class="relative p_t32 product-tui" v-show="tabIndex == 1">
                <div class="swiper-container swiper-container2" id="swiper-container3">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide four-out1" style="margin-right: none;"
                            v-for="(item, index) in recommendGoods" :key="'recommendGoods_' + index">
                            <div class="four-out">
                                <div class="four center  relative" :key="'recommendGoods_' + item.goods_id">
                                    <input type="checkbox" name="collect" :checked="item.isCollect == 1 ? true : false"
                                        disabled />
                                    <div class="fourImg_out align-center">
                                        <img :src="item.goods_thumb" class="fourImg" alt="">
                                    </div>
                                    <div style="padding: 0 20px">
                                        <div class="font-666-28 aui-ellipsis-2 h56">
                                            {{ $i18n.locale == 'en' ? item.goods_name_en
                            : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}
                                        </div>
                                        <div class="center font-333-20-500">
                                            <span class="through color-999 normal">$ {{ item.goods_price }}</span>
                                            $ {{ item.goods_cu_price }}
                                        </div>
                                    </div>
                                    <div class="btn1 align-center m_t12 margin pointer"
                                        @click="fnGoodsDetails(item.goods_id)">{{ $t('mainCK') }}</div>
                                    <div class="center font-666-18 product-intro">
                                        {{ $i18n.locale == 'en' ? item.goods_intro_en
                            : $i18n.locale == 'es' ? item.goods_intro_es : item.goods_intro_tp }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-button-next swiper-button-next1"></div>
                <div class="swiper-button-prev swiper-button-prev1"></div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Swiper from 'swiper/bundle'
import { goodsDetail, doCollect, addShopCar,getGoodsPriceBySpec } from '../api'
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            tabIndex: 0,
            tabData: [{
                title: this.$t('goodsObj.cpxq')
            }, {
                title: this.$t('mainTJCP')
            }],
            goods_guige1: [],
            goods_guige2: [],
            num: 1,
            data: {},
            recommendGoods: [],
            numAll: [],
            spec_id: '',
            packaging_id: '',
            goods_images: [],
            index: 0,
            galleryThumbs: '',
            galleryTop: '',
            goods_cu_price:0
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        selectGuige(type, idd) {
            if (type == 1) {
                this.spec_id = idd
                let params = {
                    spec_id: idd
                }
                getGoodsPriceBySpec(params).then((data) => {
                    if (data.status == 200) {
                        this.goods_cu_price = data.msg.spec_cu_price
                    }
                })
            } else {
                this.packaging_id = idd
            }
        },
        cancleCollect(e, idd) {
            console.log(e);
            let params = {
                goods_id: idd
            }
            doCollect(params).then((data) => {
                if (data.status == 200) {
                    this.data.goodsCollect = data.hasCollect
                    this.$message.success(data.msg)
                }
            })
        },
        initData() {
            let params = {
                goods_id: this.$route.params.id,
            }
            goodsDetail(params).then((data) => {
                if (data.status == 200) {
                    this.data = data.msg
                    this.goods_guige1 = data.msg.spec
                    this.goods_guige2 = data.msg.goods_packaging
                    this.spec_id = data.msg.spec[0].spec_id
                    this.packaging_id = data.msg.goods_packaging[0].packaging_id
                    this.goods_images = data.msg.goods_images
                    this.recommendGoods = data.msg.recommend_goods
                    this.goods_cu_price=data.msg.spec[0].spec_cu_price
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.listenVideo()
                        }, 100)
                    })
                    setTimeout(() => {
                        this.lookBigImg()
                    }, 500)
                }
            })
        },
        fnGoodsInCar() {
            let params = {
                goods_id: this.data.goods_id,
                spec_number: this.num,
                packaging_id: this.packaging_id,
                spec_id: this.spec_id
            }
            addShopCar(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                    this.$bus.$emit('carNum111')
                }
            })
        },
        fnBuy() {
            this.$router.push({
                path: '/sureOrder',
                query: {
                    type: 2,
                    goods_id: this.data.goods_id,
                    packaging_id: this.packaging_id,
                    spec_id: this.spec_id,
                    goods_quantity: this.num
                },
            })
        },
        handleChange(value) {
            this.num = value
        },
        fnTab(index) {
            this.tabIndex = index
            if (index == 1) {
                this.$nextTick(() => {
                    new Swiper('#swiper-container3', {
                        slidesPerView: 4,
                        spaceBetween: "4.1%",
                        slidesPerGroup: 4,
                        navigation: {
                            nextEl: '.swiper-button-next1',
                            prevEl: '.swiper-button-prev1',
                        },
                        observer: true,
                    })
                })

            }
        },
        lookBigImg() {
            let smDiv = document.getElementById('smDiv' + this.index) //获取小图容器
            // console.log(smDiv + '||' + 620)
            let bigDiv = document.querySelector('.bigDiv') //获取放大图容器
            let zoom = document.getElementById('zoom' + this.index) //获取放大器
            let bigImg = document.querySelector('.bigDiv>img') //获取放大后大图

            //光标移入悬浮小图容器
            smDiv.addEventListener('mouseover', function (e) {
                zoom.style.display = 'block'
                bigDiv.style.display = 'block'
            })
            //光标移出小图容器
            smDiv.addEventListener('mouseout', function (e) {
                zoom.style.display = 'none'
                bigDiv.style.display = 'none'
            })

            smDiv.addEventListener('mousemove', function (e) {
                //计算放大镜的top
                // offsetY:鼠标坐标到元素顶部的距离
                // offsetHeight：元素的像素高度，包含元素的垂直内边距和边框，水平滚动条的高度，且是一个整数
                var top = e.offsetY - zoom.offsetHeight / 2

                //计算放大镜的left
                // offsetX:鼠标坐标到元素的左侧的距离
                // offsetWidth:除了外边距(margin)以外,所有的宽度(高度)之和
                var left = e.offsetX - zoom.offsetWidth / 2

                //计算放大镜最大的top与left
                var maxTop = smDiv.clientHeight - zoom.offsetHeight
                var maxLeft = smDiv.clientWidth - zoom.offsetWidth

                //限制放大镜移动范围不可超过小图容器的边界
                top = top < 0 ? 0 : top
                top = top > maxTop ? maxTop : top
                left = left < 0 ? 0 : left
                left = left > maxLeft ? maxLeft : left

                // 放大镜的位置
                zoom.style.top = top + 'px'
                zoom.style.left = left + 'px'

                //放大图在容器中的位置，大图是小图的三倍，所以乘以3
                bigImg.style.top = -3 * top + 'px'
                bigImg.style.left = -3 * left + 'px'
            })
        },
        debounce(func, delay) {
            let timer
            return function () {
                clearTimeout(timer)
                timer = setTimeout(() => {
                    func.apply(this, arguments)
                }, delay)
            }
        },

        listenVideo() {
            let _this = this
            const slideChangeHandler = this.debounce(function () {
                console.log(Date.parse(new Date()) + '||' + this.activeIndex + '||655')

                _this.index = this.activeIndex
                // console.log("666||" + _this.data.goods.haveVideo);
                _this.lookBigImg()
            }, 300)
            let galleryThumbs = new Swiper('.gallery-thumbs', {
                spaceBetween: 20,
                slidesPerView: 3,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                slideToClickedSlide: true,
                navigation: {
                    nextEl: '.swiper-button-next2',
                    prevEl: '.swiper-button-prev2',
                },
            })
            let galleryTop = new Swiper('.gallery-top', {
                spaceBetween: 20,
                slideToClickedSlide: true,
                centeredSlides: true,
                navigation: {
                    nextEl: '.swiper-button-next2',
                    prevEl: '.swiper-button-prev2',
                },
                thumbs: {
                    swiper: galleryThumbs,
                },
                on: {
                    slideChange: slideChangeHandler,
                },
            })
        },
    }
}
</script>
<style scoped>
input[type='checkbox'].collect {
    position: relative;
    right: 0;
    top: 0;
    margin-right: 16px;
}


input[type='checkbox'] {
    position: absolute;
    right: 12px;
    top: 12px;
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #979797;
    border-radius: 2px;
    z-index: 1;
}

input[type='checkbox']:checked {
    border: 2px solid #018ECF;
    background: url('@/assets/checked1.png') no-repeat center center;
    background-size: 18px 18px;
}



.btn1 {
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 33px;
    height: 44px;
    background: #008DD1;
    border-radius: 36px;
    width: 82%;
    transition: all 0.3s;
}

/* .four:hover .product-intro {
    display: block;
}

.four:hover .fourImg,
.four:hover .btn1 {
    display: none;
} */



.product-intro {
    height: 40%;
    overflow-y: scroll;
    display: none;
    margin-top: 40px;
    padding: 0 20px;
    transition: all 0.3s;
}

.product-intro::-webkit-scrollbar {
    display: none;
}

.four-out .collect {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 1;
}

.four-out {
    display: flex;
    flex-wrap: wrap;
}

.four-out .fourImg_out {
    display: block;
    width: 100%;
    height: 260px;
    margin: 0 auto 12px;
    position: relative;
}

.four-out .fourImg_out .fourImg {
    object-fit: cover;
    display: block;
    width: 90%;
    height: fit-content;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.four-out .four {
    width: 100%;
    position: relative;
    display: inline-block;
    height: 442px;
    background: #F8F8F8;
    box-sizing: border-box;
    padding-top: 12px;
    transition: all 0.3s;
}

/* .four-out .four:hover {
    padding-top: 74px;
} */

.four-out .four:nth-child(4) {
    margin-right: 0;
}

.tab-item.active,
.tab-item:hover {
    font-weight: 500;
    color: #018ECF;
    text-decoration: underline;
    font-size: 16px;
}

.right1 {
    /* width: 457px; */
    width: 316px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 38px 20px 74px 20px;
    margin-left: 38px;
    height: 440px;
    box-sizing: border-box;
}





.goods_tj {
    box-sizing: border-box;
    height: 65px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 12px;
}


.card {
    background: #ffffff;
    box-sizing: border-box;
}

.left1 {
    width: 240px;
    margin-right: 38px;
}

.label1 {
    height: 42px;
    border-radius: 4px;
    padding: 0 18px;
    box-sizing: border-box;
    margin-right: 30px;
    margin-bottom: 10px;
    min-width: 87px;
    background: #F7F7F7;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
}

.label1.active,
.label1:hover {
    min-width: 87px;
    background: #018ECF;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
}

.carAll {
    height: 56px;
    background: #018ECF;
    border-radius: 28px;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
}

.fwb {
    height: auto;
    padding: 20px 0;
    box-sizing: border-box;
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    line-height: 44px;
}

.fwb>>>img {
    width: 100%;
    display: block;
}

/* 加减数 */
::v-deep .el-input-number {
    width: 100%;
    height: 56px;
    border: none;
    line-height: 56px;
}

::v-deep .el-input-number__increase {
    border-left: none;
    width: 56px;
    height: 56px;
    background: none;
    font-size: 18px;
}

::v-deep .el-input-number__decrease {
    background: none;
    border-right: none;
    width: 56px;
    height: 56px;
    font-size: 18px;
}

::v-deep .el-input-number .el-input {
    height: 56px;
}

::v-deep .el-input-number .el-input__inner {
    padding-left: 0;
    padding-right: 0;
    border: none;
    height: 42px;
    font-size: 20px;
    border: 1px solid #979797;
    width: 100%;
    line-height: 42px;
}

::v-deep .el-input__inner {
    border-radius: 28px;
    background: #F7F7F7;
}
</style>

<style scoped>
/* //////商品轮播//// */
@import url('swiper/swiper-bundle.css');

.swiper-container {
    width: 280px;
    height: 280px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-wrapper1,
.swiper-wrapper1 .swiper-slide {
    width:280px;
    height: 280px;
}

.swiper-wrapper,
.swiper-slide {
    width: 76px;
    height: 76px;
    margin: 0 20px 0 0;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.gallery-top {
    height: 40%;
    width: 100%;
}

.gallery-thumbs {
    height: 78px;
    box-sizing: border-box;
    margin-top: 12px;
    padding: 0 40px;
}

.gallery-thumbs .swiper-slide {
    height: 100%;
    opacity: 1;
}

.gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
}

.gallery-thumbs img {
    height: 78px;
    width: 78px;
    /* margin-top: 16px; */
}

.swiper-button-prev {
    width: 22px;
    height: 22px;
    background: url('../assets/swipe_jt_l2.png') no-repeat center center;
    background-size: 22px 22px;
    left: 0;
}

.swiper-button-next {
    width: 22px;
    height: 22px;
    background: url('../assets/swipe_jt_r2.png') no-repeat center center;
    background-size: 22px 22px;
    right: 0;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    display: none;
}

.swiper-slide.swiper-slide-visible {
    width: 120px !important;
}

.bigDiv {
    position: absolute;
    top: 0px;
    left: 400px;
    width: 480px;
    height: 480px;
    overflow: hidden;
    display: none;
    z-index: 999;
  
}

.zoom {
    position: absolute;
    width: 110px;
    height: 111px;
    background-color: #8484847b;
    top: 0;
    left: 0;
    cursor: move;
    pointer-events: none;
    display: none;
}

.bigDiv>img {
    position: absolute;
    top: 0;
    left: 0;
}

/* ///////////////推荐产品轮播///////////////// */

.product-tui .swiper-button-prev {
    width: 100px;
    height: 100px;
    background: url('../assets/swipe_jt_l1.png') no-repeat center center;
    background-size: 100px 100px;
    /* margin-left: 22px; */
    top: calc(100%/2 - 20px);
}

.product-tui .swiper-button-next {
    width: 100px;
    height: 100px;
    background: url('../assets/swipe_jt_r1.png') no-repeat center center;
    background-size: 100px 100px;
    top: calc(100%/2 - 20px);
}

.swiper-container2 {
    height: 442px;
    width: 80%;
}

.swiper-container2 .swiper-slide {
    position: relative;
    height: 442px;
    width: 25%;
}

.swiper-container2 .swiper-wrapper {
    width: 100%;
    height: 100%
}
</style>