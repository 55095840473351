<template>
    <div>
        <Header />
        <div class="box">
            <div class="flex-wrap">
                <div class="box1 m_r46">
                    <div class="title2 align-start" style="padding:0;">
                       {{ $t('footAll.dz') }}
                    </div>
                    <div class="box4 font-333-22 pointer" v-show="addressObj.addr_id" style="margin-bottom: 40px;"
                        @click="fnSelectAddress">
                        <div class="font-018ECF-24-500 m_b30"> {{ addressObj.addr_xing }}{{ addressObj.addr_name }} </div>
                        <div class="m_b12">{{ addressObj.addr_address }} （{{ addressObj.addr_postal }}）</div>
                        <div class="">{{ addressObj.addr_phone }}</div>
                    </div>
                    <div class="title2 align-start pointer" v-show="!addressObj.addr_id" style="padding:0;height: auto;"
                        @click="fnSelectAddress">
                        <img :src="require('@/assets/jia1.png')" alt="" class="jia1">
                        <div>{{ $t('carObj.tjdz') }}</div>
                    </div>
                </div>

                <div class="flex-con">
                    <div class="box2">
                        <div class="title2 b-b-979797 align-start">
                            {{ $t('carObj.cpqd') }}
                        </div>
                        <div class="box3">
                            <div class="goods-item align-start font-333-24" v-for="(item, index) in data.msg" :key="index">
                                <img :src="item.goods_thumb" alt="">
                                <div class="font-333-24-500" style="width:38%;">{{ $i18n.locale == 'en' ?
                                    item.goods_name_en
                                    : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
                                <div style="width:15%;">{{ item.packaging_name }} <br> {{ item.spec_value }}</div>
                                <div style="width:10%;">X {{ item.sc_number || goods_quantity}}</div>
                                <div>$ {{ item.spec_cu_price }}</div>
                            </div>
                        </div>
                        <div class="title2 b-t-979797 align-between">
                            <div>{{ $t('goodsObj.zj') }}</div>
                            <div>$ {{ data.totalPrice }}</div>
                        </div>
                    </div>
                    <div class="align-end">
                        <div class="btn align-center pointer" @click="initCommitOrder">{{ $t('carObj.tjdd') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { showConfirmOrder, shopCarCommitOrder, showAddress, goodsCommitOrder } from '../api'
export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            data: [],
            addressObj: {},
            goods_quantity:this.$route.query.goods_quantity,
            fromName:''
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
        console.log(from.name);
            vm.fromName = from.name
        })
    },
    mounted() {
        this.initShowConfirmOrder()
        if(this.fromName=='car' || this.fromName=='productDetails' || this.fromName==null){
        localStorage.setItem('wlAddressPc', '')
        this.initShowAddress()
        }else{
        this.addressObj=JSON.parse(localStorage.getItem('wlAddressPc'));
        }
    },
    methods: {
        initCommitOrder() {
            if (!this.addressObj.addr_id) {
                this.$message.error(this.$t('carObj.qxzdz'))
                return
            }

            let params
            if (this.$route.query.type == 1) {
                params = {
                    sc_ids: this.$route.query.sc_ids,
                    addr_id: this.addressObj.addr_id
                }
                shopCarCommitOrder(params).then((data) => {
                    if (data.status == 200) {
                        this.$router.push({
                            name: 'orderCenter',
                            params: {
                                id: 1
                            },
                        })
                    }
                })
            } else {
                params = {
                    addr_id: this.addressObj.addr_id,
                    goods_id: this.$route.query.goods_id,
                    packaging_id: this.$route.query.packaging_id,
                    spec_id: this.$route.query.spec_id,
                    goods_quantity: this.goods_quantity
                }
                goodsCommitOrder(params).then((data) => {
                    if (data.status == 200) {
                        this.$router.push({
                            name: 'orderCenter',
                            params: {
                                id: 1
                            },
                        })
                    }
                })
            }

        },
        initShowAddress() {
            let params = {}
            showAddress(params).then((data) => {
                if (data.status == 200) {
                    this.addressObj = data.msg
                }
            })
        },
        fnSelectAddress() {
            this.$router.push({
                path: 'myAddress',
                query: {
                    from: 1
                },
            })
        },
        initShowConfirmOrder() {
            let params = {
                sc_ids: this.$route.query.sc_ids,
                goods_id: this.$route.query.goods_id,
                packaging_id: this.$route.query.packaging_id,
                spec_id: this.$route.query.spec_id,
                goods_quantity: this.$route.query.goods_quantity
            }
            showConfirmOrder(params).then((data) => {
                if (data.status == 200) {
                    this.data = data
                }
            })
        }
    }

}
</script>
  
<style scoped>
.jia1 {
    width: 52px;
    height: 52px;
    margin-right: 22px;
}

.box4 {
    background: #EAEFF6;
    border-radius: 4px;
    border: 1px solid #008DD1;
    padding: 24px 30px;
}

.btn {
    min-width: 40%;
    padding: 0 44px;
    box-sizing: border-box;
    height: 75px;
    background: #018ECF;
    border-radius: 4px;
    border: 1px solid #2A76A8;
    margin-top: 60px;
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 33px;
}

.goods-item {
    margin-top: 40px;
}

.goods-item img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    background: #F7F7F7;
    margin-right: 30px;
}

.box3 {
    padding: 0 40px 40px;
}

.title2 {
    font-size: 22px;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
    height: 69px;
    padding: 0 42px;
    box-sizing: border-box;
}

.box2 {
    border-radius: 4px;
    border: 1px solid #979797;
}

.full-name {
    width: 33%;
}

.info-all {
    padding: 40px;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid #979797;
}

.title1 {
    font-size: 22px;
    font-weight: 500;
    color: #008DD1;
    line-height: 30px;
    background: #EAEFF6;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #008DD1;
    height: 69px;
    padding-left: 42px;
}

.box1 {
    width: 45%;
}

.box {
    padding: 30px 60px 60px;
    background-color: #fff;
}
</style>