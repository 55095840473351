<template>
  <div>
    <Header :num111="num" />
    <div class="bread align-start font-333-18-500">
      <div>{{ $t('headSY') }}</div>
      <img :src="require('@/assets/swipe_jt_r2.png')" alt="" style="width:22px;height:22px;margin:0 20px;">
      <div class="color-2A76A8">{{ type == 1 ? $t('footAll.sjbhzc') : type == 2 ? $t('footAll.yszc') : type == 3
      ? $t('footAll.gsjs') : type == 4 ? $t('footAll.lxwm') : type == 7 ? $t('headGYWM') : '' }}</div>
    </div>
    <div class="box">
      <div class="title">{{ type == 1 ? $t('footAll.sjbhzc') : type == 2 ? $t('footAll.yszc') : type == 3
      ? $t('footAll.gsjs') : type == 4 ? $t('footAll.lxwm') : type == 7 ? $t('headGYWM') : '' }}</div>
      <div v-html="$t('content')">

      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { getConfig } from '../api'
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      type: this.$route.params.type,
      num: -1
    }
  },
  methods: {
    initData() {
      getConfig({
        type: this.type
      }).then((data) => {
        if (data.status == 200) {
          this.$i18n.mergeLocaleMessage('en', { content: data.msg.content_en })
          this.$i18n.mergeLocaleMessage('es', { content: data.msg.content_es })
          this.$i18n.mergeLocaleMessage('tp', { content: data.msg.content_tp })
        }
      })
    },
  },
  created() {
    this.num = this.type == 7 ? 0 : this.type == 4 ? 1 : this.type == 2 ? 2 : -1
  },
  mounted() {
    this.initData()
  },

}
</script>


<style scoped>
.bread {
  height: 45px;
  background: #f8f8f8;
  padding: 0 60px;
}

.box {
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 15px;
  box-sizing: border-box;
  text-align: center;
}
</style>