<template>

  <div style="width: 100%;height: 100vh;display: flex;">
    <div class="left">
      <img :src="require('@/assets/loginlogo.png')" fit="cover" class=" logo" alt="" />
    </div>
    <div class="right" style="background-color: #FFFFFF;">
      <el-dropdown @command="handleCommand" style="margin-top: 30px;margin-right: 85px;">
          <span class="el-dropdown-link">
            {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="en">English</el-dropdown-item>
            <el-dropdown-item command="es">Español</el-dropdown-item>
            <el-dropdown-item command="tp">Português</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      
      <div class="box" style="width: 725px;box-sizing: border-box;padding: 0 58px 0 58px;">
      
      <div class="info">{{ $t('logon.dl1') }}</div>
      
      <div class="flex-wrap">
       
       
        <div class="right flex-con">
          <div style="text-align: left;font-size: 14px;color: #666666;margin-bottom: 12px;">{{ $t('logon.d131') }}</div>
          <div class="one flex-wrap">
            <input type="text" class="flex-con" :placeholder="$t('logon.dl17')" v-model="user_name" :readonly="status==1?true:false">
          </div>
          <div style="text-align: left;font-size: 14px;color: #666666;margin-bottom: 12px;">{{ $t('logon.d132') }}</div>
          <div class="one flex-wrap">
            <input type="text" class="flex-con" :placeholder="$t('logon.dl19')" v-model="email" readonly>
          </div>
          <div style="text-align: left;font-size: 14px;color: #666666;margin-bottom: 12px;">{{ $t('logon.d133') }}</div>
          <div class="one flex-wrap">
            <input type="text" class="flex-con" :placeholder="$t('logon.dl20')" v-model="user_phone" :readonly="status==1?true:false">
          </div>
          <div style="text-align: left;font-size: 14px;color: #666666;margin-bottom: 12px;">{{ $t('logon.d134') }}</div>
          <div class="one flex-wrap">
            <input type="text" class="flex-con" :placeholder="$t('logon.dl21')" v-model="user_company" :readonly="status==1?true:false">
          </div>
          <div style="text-align: left;font-size: 14px;color: #666666;margin-bottom: 12px;">{{ $t('logon.d135') }}</div>
          <div class="one flex-wrap">
            <input type="text" class="flex-con" :placeholder="$t('logon.dl22')" v-model="user_position" :readonly="status==1?true:false">
          </div>
          <div class="button">
            <div class="btn pointer" v-if="status==1">{{ $t('logon.dl23') }}</div>
            <div class="btn pointer" v-else-if="status==2" @click="submit()">{{ $t('logon.dl24') }}</div>
            <div class="btn pointer" v-else @click="submit()">{{ $t('logon.dl25') }}</div>
          </div>
        </div>
      </div>

    </div>
    </div>
    <el-dialog :title="$t('logon.dl26')" :visible.sync="dialogVisible">
      <div class="dmiddle">
        <div class="dtitle flex-wrap">
          <div class="flex-con"></div>
          <img src="../assets/message.png" class="message">
          <div>{{ $t('logon.dl27') }}</div>
          <div class="flex-con"></div>
        </div>
        <div class="dinfo">
          {{user_refuse_reason}}
        </div>
      </div>
    </el-dialog>
  </div>
  
</template>
  
<script>
import { commitCheck,userBaseInfo } from '../api'
export default {
  data() {
    return {
      user_name:'',
      user_xing: '',
      email:'',
      user_phone: '',
      user_company:'',
      user_position: '',
      status:'',
      dialogVisible:false,
      user_refuse_reason:'',
      language: '',
    }
  },
  methods: {
    submit() {
      if (this.user_name == '') {
        this.$message.error(this.$t('logon.dl17'))
        return;
      }
      
      if (this.user_phone == '') {
        this.$message.error(this.$t('logon.dl20'))
        return;
      }
      if (this.user_company == '') {
        this.$message.error(this.$t('logon.dl21'));
        return;
      }
      if (this.user_position == '') {
        this.$message.error(this.$t('logon.dl22'))
        return;
      }
      commitCheck({
        user_id:this.$route.params.user_id,
        user_name: this.user_name,
        user_xing: '',
        user_phone: this.user_phone,
        user_company: this.user_company,
        user_position: this.user_position,
        user_email:this.email
      }).then((data) => {
        if (data.status == 200) {
            this.status=1;
            this.$message.success(this.$t('logon.dl28'))
        }
      })
    },
    initData(){
      userBaseInfo({
        user_id:this.$route.params.user_id,
      }).then((data) => {
        if (data.status == 200) {
          this.user_name =data.msg.user_name;
          this.user_phone =data.msg.user_phone;
         
          this.user_company =data.msg.user_company;
          this.user_position = data.msg.user_position;
          this.user_refuse_reason=data.msg.user_refuse_reason||'';
          this.dialogVisible=data.msg.user_status==3?true:false;
        }
      })
    },
    handleCommand(command) {
      this.language = command == 'en' ? 'English' : command == 'es' ? 'Español' : 'Português';
      this.lan = command;
      this.$i18n.locale = command;
      localStorage.setItem('lang', command)
    },
  },
  mounted() {

    this.email = this.$route.params.email;
    if(this.$route.params.type==1){
        this.status = this.$route.params.status;
        console.log(this.status);
        this.dialogVisible=this.status==3?true:false;
        this.initData();
    }
    this.language = this.lan == 'en' ? 'English' : this.lan == 'es' ? 'Español' : 'Português';

  }
}
</script>
  
  
<style scoped>
.head_contain {
  width: 100%;
  min-height: 110px;
  font-size: 16px;
  line-height: 22px;
  color: #008dd1;
}

.head_top {
  background-color: #fff;
  height: 90px;
  padding: 0 60px;
}

.head_top > div,
.head_top > img {
  margin-left: 38px;
}

.logo {
  width: 269px;
  height: 56px;
  margin-left: 60px;
  margin-top: 70px;
}

.head_tab {
  height: 42px;
  background: #008dd1;
  padding: 0 60px;
  color: #fff;
  font-weight: normal;
}



.title {
  font-size: 22px;
  font-weight: 400;
  color: #333333;
  line-height: 15px;
  text-align: left;
  margin-bottom: 30px;
}

.info {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  line-height: 21px;
  text-align: center;
  margin-bottom: 50px;
}

.tu1 {
  display: block;
  width: 75%;
  height: 114px;
  margin: 22px auto 0;
}

.xian {
  width: 1px;
  border-left: 1px solid #dddddd;
}

.right {
flex: 1;

}

.one {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  margin-bottom: 18px;
}

.kuang {
  width: 65px;
  border-right: 1px solid #dddddd;
}

.icon {
  display: block;
  width: 36px;
  height: 36px;
  margin: 16px auto;
}

input[type="text"],
input[type="password"],
input[type="tel"] {
  background: rgba(0, 0, 0, 0);
  border: none;
  box-sizing: border-box;
  padding: 0 8px;
  color: #333;
  font-size: 15px;
  outline: none;
 
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder {
  color: #999;
}

.getCode {
  line-height: 40px;
  width: 113px;
  background: #018ecf;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.button {
  padding: 0 24px;
}

.btn {
  width: 100%;
  height: 48px;
  background: #008dd1;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  line-height: 48px;
  text-align: center;
  margin-top: 60px;
}

.icon1 {
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 16px;
}



.leftTxt {
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  width: 75%;
  margin: auto;
}

.oneTxt {
  align-items: center;
}

.bottom {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  margin-top: 30px;
}
.left{
  width: 1195px;
  height: 100vh;
  background-image: url('@/assets/loginbg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.el-dropdown {
  font-size: 18px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #999;
}

.el-icon-arrow-down {
  font-size: 18px;
}
</style>