<template>
    <div class="bg-fff">
        <Header />
        <div class="flex-wrap box margin">
            <div class="left1">
                <MyInfo :num111="7" />
            </div>
            <div class="flex-con four-box">
                <div class="font-333-20-500 align-between">
                    <div>{{ $t('addressObj.dzgl') }}</div>
                </div>
                <div class="four-out">
                    <div class="four" :class="{ active: item.addr_default == 1, pointer: from }"
                        v-for="(item, index) in addressListObj" :key="'address_' + index" @click.stop="fnSelect(item)">
                        <div class="font-018ECF-18-500 m_b12">{{ item.addr_xing + item.addr_name }}{{ item.addr_default ==
                            1 ? ('-' + $t('addressObj.mrdz')) : '' }}</div>
                        <div class="font-333-14">
                            <div class="m_t8">{{ item.addr_address || '' }}</div>
                            <div class="m_t8">{{ item.addr_postal || '' }}（{{ $t('addressObj.yb') }}）</div>
                            <div class="m_t8">{{ item.addr_phone || '' }}</div>
                        </div>
                        <div class="icon-all align-center3">
                            <img :src="require('@/assets/edit.png')" alt="" class="pointer"
                                @click.stop="fnAddAddress(1, item.addr_id)">
                            <img :src="require('@/assets/delete.png')" alt="" class="pointer"
                                @click.stop="fnDelAddress(item.addr_id)">
                        </div>
                    </div>
                </div>
                <div class="title2 align-start">
                    <img :src="require('@/assets/jia1.png')" alt="" class="jia1 pointer" @click="fnAddAddress">
                    <div>{{ $t('addressObj.xdz') }}</div>
                </div>

            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import { addressList, delAddress } from '../api'
export default {
    components: {
        Header,
        Footer,
        MyInfo
    },
    data() {
        return {
            addressListObj: [],
            from: this.$route.query.from,
            item: {}
        }
    },
    mounted() {
        this.initAddressList()
    },
    destroyed() {
        this.$bus.$emit(
            'address',
            this.item
        )
    },
    methods: {
        fnSelect(item) {
            if (this.from) {
                localStorage.setItem('wlAddressPc', JSON.stringify(item));
                this.$router.go(-1)
            }
        },
        fnDelAddress(idd) {
            let params = {
                addr_id: idd
            }
            delAddress(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                    this.initAddressList()
                }
            })
        },
        initAddressList() {
            let params = {}
            addressList(params).then((data) => {
                if (data.status == 200) {
                    this.addressListObj = data.msg
                }
            })
        },
        fnAddAddress(type, idd) {//type==1,idd有值为 编辑地址 否则添加地址
            if (type == 1) {
                this.$router.push({
                    path: `/newAddress/${idd}`
                });
            } else {
                this.$router.push({
                    path: 'newAddress'
                });
            }
        }
    }

}
</script>
  
<style scoped>
.title2 {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    height: 28px;
    margin-top: 12px;
    box-sizing: border-box;
}

.jia1 {
    width: 28px;
    height: 28px;
    margin-right: 12px;
}

.four .icon-all {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 16px 16px 16px 0;
    box-sizing: border-box;
}

.four .icon-all img {
    width: 28px;
    height: 28px;
}

.four-box {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 16px;
}

.four-out {
    display: flex;
    flex-wrap: wrap;
}

.four-out .four {
    margin-right: 12px;
    margin-top: 16px;
    position: relative;
    display: inline-block;
    width: calc((100% - 12px)/2);
    box-sizing: border-box;
    padding: 16px;
    transition: all 0.3s;
    border-radius: 4px;
    border: 1px solid #008DD1;
}

.four-out .four.active {
    background: #EAEFF6;
}

.four-out .four:nth-child(2n) {
    margin-right: 0;
}

.btn1 {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
    height: 34px;
    background: #008DD1;
    border-radius: 17px;
    width: 208px;
    transition: all 0.3s;
}

.four:hover .product-intro {
    display: block;
}

.four:hover .fourImg,
.four:hover .btn1 {
    display: none;
}

.product-intro {
    height: 40%;
    /* overflow-y: scroll; */
    display: none;
    margin-top: 20px;
    padding: 0 20px;
    transition: all 0.3s;
}

.product-intro::-webkit-scrollbar {
    display: none;
}

.collect {
    display: none;
    width: 28px;
    height: 28px;
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 1;
}

.left1 {
    width: 232px;
    margin-right: 30px;
}

.box {
    padding: 30px 0;
    width: 1200px;
}
</style>