<template>
    <div class="bg-fff">
        <Header />
        <div class="flex-wrap box margin">
            <div class="left1">
                <MyInfo :num111="8" />
            </div>
            <div class="flex-con four-box">
                <div class="font-333-20-500 align-between">
                    <div>My Collection</div>
                    <!-- <div class="font-999-18-500">edit</div> -->
                </div>
                <div class="four-out">
                    <div class="four center pointer relative" v-for="(item, index) in goods"
                        :key="'goods_' + item.goods_id + '_' + index">
                        <input type="checkbox" name="collect" checked @click.stop="() => { }"
                            @click='(e) => { cancleCollect(e, item.goods_id) }' />
                        <el-image fit="cover" :src="item.goods_thumb" class="fourImg"></el-image>
                        <div style="padding: 0 20px">
                            <div class="font-666-14 aui-ellipsis-2 h40 m_b12">
                                {{ $i18n.locale == 'en' ? item.goods_name_en
                                    : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}
                            </div>
                            <div class="center font-333-16-500">
                                <span class="through color-999 normal">$ {{ item.goods_price || '' }}</span>
                                $ {{ item.goods_cu_price || '' }}
                            </div>
                        </div>
                        <div class="center font-666-14 product-intro">
                            {{ $i18n.locale == 'en' ? item.goods_intro_en
                                : $i18n.locale == 'es' ? item.goods_intro_es : item.goods_intro_tp }}
                        </div>
                        <div class="btn1 align-center m_t6 margin" @click="fnGoodsDetails(item.goods_id)">{{ $t('mainCK') }}</div>
                    </div>
                </div>
                <div class="align-center m_t12" v-show="total > 0">
                    <Page :limit="limit" :totalNum="total" @onPage="changePage" />
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import Page from '@/components/Page.vue'
import { limit, myCollect, doCollect } from '../api'
export default {
    components: {
        Header,
        Footer,
        MyInfo,
        Page
    },
    data() {
        return {
            page: 1,
            limit: limit,
            total: 0,
            goods: []
        }
    },
    mounted() {
        this.initMyCollect()
    },
    methods: {
        cancleCollect(e, idd) {
            if (e.target.checked) {
                return
            }
            let params = {
                goods_id: idd
            }
            doCollect(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                    this.initMyCollect();
                }
            })
        },
        initMyCollect() {
            let params = {
                page: this.page
            }
            myCollect(params).then((data) => {
                if (data.status == 200) {
                    this.goods = data.msg
                    // this.total = data.count
                }
            })
        },
        changePage(childrenData) {
            this.page = childrenData;
            this.initMyCollect();
        },
    }

}
</script>
  
  
<style scoped>
input[type='checkbox'] {
    position: absolute;
    right: 12px;
    top: 12px;
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #979797;
    border-radius: 2px;
    z-index: 1;
}

input[type='checkbox']:checked {
    border: 2px solid #018ECF;
    background: url('@/assets/checked1.png') no-repeat center center;
    background-size: 18px 18px;
}

.four-box {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 16px;
}

.four-out {
    display: flex;
    flex-wrap: wrap;
}

.fourImg {
    display: block;
    width: 158px;
    height: 158px;
    margin: 0 auto 6px;
    transition: all 0.3s;
}

.four-out .four {
    margin-right: 12px;
    margin-top: 12px;
    position: relative;
    display: inline-block;
    width: calc((100% - 24px)/3);
    background: #F8F8F8;
    box-sizing: border-box;
    padding: 6px 0 12px;
    transition: all 0.3s;
    height: 296px;
}

.four-out .four:hover {
    padding-top: 30px;
}

.four-out .four:nth-child(3n) {
    margin-right: 0;
}

.btn1 {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
    height: 34px;
    background: #008DD1;
    border-radius: 17px;
    width: 208px;
    transition: all 0.3s;
    display: none;
}

.four:hover .product-intro {
    display: block;
}

.four:hover .fourImg{
    display: none;
}
.four:hover .btn1 {
    display: block;
}

.product-intro {
    height: 40%;
    /* overflow-y: scroll; */
    display: none;
    margin-top: 20px;
    padding: 0 20px;
    transition: all 0.3s;
}

.product-intro::-webkit-scrollbar {
    display: none;
}

.collect {
    display: none;
    width: 28px;
    height: 28px;
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 1;
}

.left1 {
    width: 232px;
    margin-right: 30px;
}

.box {
    padding: 30px 0;
    width: 1200px;
}
</style>