<template>
  <div>
    <Header />
    <div style="width:100% ;background-color: #ffffff;display: flex;justify-content: center;">
      <div class="box">
      <div class="font-333-20-500 m_b6">{{ $t('orderObj.ddxq') }}</div>
      <div class="font-333-18">{{ $t('orderObj.ddbh') }}#{{ data.order_number }}</div>
      <div class="font-333-18 m_t6">{{ $t('orderObj.ddsj') }} {{ data.order_time }}</div>
      <div style="padding:30px 0 30px 0 ;">
        <el-steps :active="data.order_status - 1" align-center v-show="data.order_status < 6">
          <el-step :title="$t('headGRZXObj.dqr')" icon="el-icon-edit"></el-step>
          <el-step :title="$t('headGRZXObj.dfk')" :icon="data.order_status > 1 ? 'el-icon-edit' : 'el-icon-picture'"></el-step>
          <el-step :title="$t('headGRZXObj.dfh')" :icon="data.order_status > 2 ? 'el-icon-edit' : 'el-icon-picture'"></el-step>
          <el-step :title="$t('headGRZXObj.dsh')" :icon="data.order_status > 3 ? 'el-icon-edit' : 'el-icon-picture'"></el-step>
          <el-step :title="$t('headGRZXObj.ywc')" :icon="data.order_status > 4 ? 'el-icon-edit' : 'el-icon-picture'"></el-step>
        </el-steps>
      </div>
      <div class="flex-con">
          <div class="box2">
            <div class="title2  align-start" style="border-bottom: 1px solid #E1E1E1;">
              {{ $t('carObj.cpqd') }}
            </div>
            <div class="box3">
              <div class="goods-item flex-wrap align-start font-333-18" v-for="(item, index) in data.orderGoods" :key="index">
                <img :src="item.goods_thumb" alt="">
                <div class="font-333-18-500 border-box p_r20" style="width:38%;">{{ $i18n.locale == 'en'
                ?
                item.goods_name_en
                : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
                <div style="width:16%;">{{ item.packaging_name }} <br> {{ item.spec_value }}</div>
                <div style="width:10%;">X {{ item.of_quantity }}</div>
                <div class="right flex-con">$ {{ item.of_price }}</div>
              </div>
            </div>
            <div class="title2  align-end" style="border-top: 1px solid #E1E1E1;height: 68px;">
              <div style="margin-right: 30px;font-size: 18px;color: #666666;">{{ $t('goodsObj.zj') }}</div>
              <div class="font-333-20" style="font-size: 20px;">$ {{ data.order_total_price }}</div>
            </div>
          </div>
         
        </div>
      <div >
        <div class="m_r30 box1">
          
          <div class="info-all">
            <div class="title1 ">
            {{ $t('orderObj.hwys') }}
          </div>
            <div class="font-333-18-500 m_b14">{{ $t('footAll.dz') }}</div>
            <div class="flex-wrap m_b14" v-if="data.order_xjd_status==3">
              <div class="full-name">
                <div class="font-666-14 m_b10">{{ $t('orderObj.qm') }}</div>
                <div class="font-018ECF-18-500"> {{ data.order_xjd_gs_name || '' }}
                </div>
              </div>
              <div class="flex-con">
                <div class="font-666-14 m_b10">{{ $t('orderObj.dh') }}</div>
                <div class="font-333-14-500">{{ data.order_xjd_user_phone || '' }}</div>
              </div>
            </div>
            <div class="flex-wrap m_b14" v-else>
              <div class="full-name">
                <div class="font-666-14 m_b10">{{ $t('orderObj.qm') }}</div>
                <div class="font-018ECF-18-500"> {{ data.addr_xing || '' }}{{ data.addr_name || '' }}
                </div>
              </div>
              <div class="flex-con">
                <div class="font-666-14 m_b10">{{ $t('orderObj.dh') }}</div>
                <div class="font-333-14-500">{{ data.addr_phone || '' }}</div>
              </div>
            </div>
            <!-- <div class="flex-wrap m_b14">
                            <div class="full-name">
                                <div class="font-666-16 m_b10">province</div>
                                <div class="font-333-18-500">Ciudad，STATE 0564673</div>
                            </div>
                            <div class="flex-con">
                                <div class="font-666-16 m_b10">country</div>
                                <div class="font-333-18-500">China</div>
                            </div>
                            <div class="flex-con">
                                <div class="font-666-16 m_b10">{{ $t('orderObj.dh') }}</div>
                                <div class="font-333-18-500">{{ data.addr_phone || '' }}</div>
                            </div>
                        </div> -->
                        <div class="flex-wrap m_b14" v-if="data.order_xjd_status==3">
              <div class="full-name">
                <div class="font-333-18-500 m_b14" style="color: #333333;">{{ $t('addressObj.xxdz') }}</div>
                <div class="font-333-500" style="font-size: 14px;">{{ data.order_xjd_gs_address || '' }}</div>
              </div>
            </div>
            <div class="flex-wrap m_b14" v-else>
              <div class="full-name">
                <div class="font-333-18-500 m_b14" style="color: #333333;">{{ $t('addressObj.xxdz') }}</div>
                <div class="font-333-500" style="font-size: 14px;">{{ data.addr_address || '' }}</div>
              </div>
            </div>
            <div class="font-333-18-500 m_b10"   v-show="data.order_status == 4 || data.order_status == 5">{{ $t('orderObj.wl') }}</div>
            <div class="font-333" style="line-height: 20px;color: #333333;white-space: pre-line"  v-show="data.order_status == 4 || data.order_status == 5"> {{ data.order_wuLiu_company || '' }}
              {{ data.order_wuLiu_code || ''
                            }} <br>
   
                            
                            {{ data.order_wuLiu_desc || '' }} </div>
                            
          </div>

        </div>
        <div class="align-end">
            <div class="btn align-center pointer" v-show="data.order_status == 1" @click.stop="fnSure(1,data.order_id)">{{
                $t('orderObj.qrdd') }}</div>
            <div class="btn align-center pointer" v-show="data.order_status == 1 || data.order_status == 2" @click.stop="fnSure(2,data.order_id)">{{ $t('orderObj.qxdd')
                            }}</div>
            <div class="btn align-center pointer" v-show="data.order_status == 4" @click.stop="fnSure(3,data.order_id)">{{
                $t('orderObj.qrsh') }}</div>
            <div class="btn align-center pointer" v-show="data.order_status == 5 && data.orderGoods.length == 1" @click.stop="fnGoodsDetails(data.orderGoods[0].of_goods_id)">{{ $t('orderObj.zlyd') }}</div>
          </div>
        
      </div>
    </div>
    </div>
    <Footer />
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" append-to-body>
      <span>{{ts}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submit" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { orderDetail, sureOrder, overOrder, cancelOrder } from '../api'
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      data: {},
      ts: '',
      dialogVisible: false,
      orderId: '',
      where: 0
    }
  },
  mounted() {
    this.initOrderDetail()
  },
  methods: {
    initOrderDetail() {
      let params = {
        order_id: this.$route.params.order_id
      }
      orderDetail(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          console.log(data.msg);
          console.log(888);
          
        }
      })
    },
    fnSure(type, id) {
      this.dialogVisible = true;
      this.where = type;
      if (this.where == 1) {
        this.ts = "是否确认订单"
      } else if (this.where == 2) {
        this.ts = "是否取消订单"
      } else {
        this.ts = "是否确认收货"
      }
      this.orderId = id;
    },
    submit() {
      if (this.where == 1) {
        this.fnSureOrder(this.orderId);
      } else if (this.where == 2) {
        this.fnCancleOrder(this.orderId);
      } else {
        this.fnSureSh(this.orderId)
      }
    },
    fnSureOrder(idd) {
      let params = {
        order_id: idd
      }
      sureOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.dialogVisible=false;
          this.initOrderDetail()
        }
      })
    },
    fnCancleOrder(idd) {
      let params = {
        order_id: idd
      }
      cancelOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.dialogVisible=false;
          this.initOrderDetail()
        }
      })
    },
    fnSureSh(idd) {
      let params = {
        order_id: idd
      }
      overOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.dialogVisible=false;
          this.initOrderDetail()
        }
      })
    },
    handleClose(done) {
        done();
    },
  }

}
</script>

<style scoped>
.btn {
  min-width: 250px;
  height: 60px;
  background: #018ecf;
  border-radius: 4px;
  border: 1px solid #2a76a8;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
  margin-left: 20px;
}

.goods-item {
  margin-top: 12px;
}

.goods-item img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  background: #f7f7f7;
  margin-right: 30px;
}

.box3 {
  padding: 4px 42px 16px;
}

.title2 {
  font-size: 22px;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  height: 50px;
  padding: 0 42px;
  box-sizing: border-box;

  }

.box2 {
  border-radius: 4px 4px 0 0;
  border: 1px solid #E1E1E1;
  border-bottom: 0;
}

.full-name {
  min-width: 38%;
  padding-right: 20px;
  box-sizing: border-box;
}

.info-all {
  padding: 16px 42px;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #E1E1E1;
}

.title1 {
  font-size:18px;
  font-weight: 500;
  color: #008dd1;
  line-height: 30px;



  height: 50px;
 
}

.box1 {
  width: 1200px;
}

.box {
  padding: 20px 60px 50px;
  background-color: #fff;
  width: 1200px;
}

::v-deep .el-step.is-center .el-step__line {
  left: 70%;
  right: -30%;
}

::v-deep .el-step.is-horizontal .el-step__line {
  height: 4px;
  top: 18px;
  background-color: #008dd1;
}

::v-deep .el-step__head.is-finish .el-step__line {
  height: 4px;
  background-color: #f9be00;
}

::v-deep .el-step__head.is-finish .el-step__line .el-step__line-inner {
  border-width: 0 !important;
}

::v-deep .el-step__icon.is-icon {
  width: 40px;
  height: 40px;
}

::v-deep .el-icon-edit:before {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("@/assets/order_status1.png");
  background-size: 100% 100%;
}

::v-deep .el-icon-picture:before {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("@/assets/order_status2.png");
  background-size: 100% 100%;
}

::v-deep .el-step__title.is-finish,
::v-deep .el-step__title.is-process,
::v-deep .el-step__title.is-wait {
  color: #666666;
  font-weight: 500;
}

::v-deep .el-step__title {
  font-size: 20px;
  line-height: 28px;
  margin-top: 10px;
}
</style>