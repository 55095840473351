<template>
    <div class="bg-fff">
        <Header />
        <div class="flex-wrap box margin">
            <div class="left1">
                <MyInfo :num111="7" />
            </div>
            <div class="flex-con four-box">
                <div class="font-333-20-500" style="margin-bottom:12px;">
                    <div>{{ addr_id ? $t('addressObj.bjdz') : $t('addressObj.xdz') }}</div>
                </div>
                <div class="box1">
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.ming') + ' *'"
                            v-model="addressObj.addr_name" value="">
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.xing') + ' *'"
                            v-model="addressObj.addr_xing" value="">
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.gs') + ' *'"
                            v-model="addressObj.addr_company" value="">
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('addressObj.xxdz') + ' *'"
                            v-model="addressObj.addr_address" value="">
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('addressObj.yb') + ' *'"
                            v-model="addressObj.addr_postal" value="">
                    </div>
                    <div class="one flex-wrap" style="margin: 0;">
                        <div class="kuang">
                            <img :src="require('@/assets/phone.png')" class="icon">
                        </div>
                        <div class="xian"></div>
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.dhhm') + ' *'"
                            v-model="addressObj.addr_phone" value="">
                    </div>
                    <div class="align-start font-666-14" style="margin: 12px 0 28px;">
                        <input type="checkbox" name="checkbox" v-model="checked" />
                        {{ $t('addressObj.mo') }}
                    </div>

                    <div class="btn" @click="initCreateAddress">{{ addr_id ? $t('addressObj.qr') : $t('jbXX.tj') }}</div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import { createAddress, showAddress, updateAddress } from '../api'
export default {
    components: {
        Header,
        Footer,
        MyInfo
    },
    data() {
        return {
            addressObj: {},
            checked: false,
            addr_id: this.$route.params.id
        }
    },
    mounted() {
        if (this.addr_id) {
            this.initShowAddress()
        }
    },
    methods: {
        initShowAddress() {
            let params = {
                addr_id: this.addr_id
            }
            showAddress(params).then((data) => {
                if (data.status == 200) {
                    this.addressObj = data.msg
                    this.checked = this.addressObj.addr_default == 1 ? true : false
                }
            })
        },
        initCreateAddress() {
            if (!this.addressObj.addr_name) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.ming'))
                return;
            }
            if (!this.addressObj.addr_xing) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.xing'))
                return;
            }
            if (!this.addressObj.addr_company) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.gs'))
                return;
            }
            if (!this.addressObj.addr_address) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('addressObj.xxdz'))
                return;
            }
            if (!this.addressObj.addr_postal) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('addressObj.yb'))
                return;
            }
            if (!this.addressObj.addr_phone) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.dhhm'))
                return;
            }
            let params = {
                addr_xing: this.addressObj.addr_xing || '',
                addr_name: this.addressObj.addr_name || '',
                addr_phone: this.addressObj.addr_phone || '',
                addr_postal: this.addressObj.addr_postal || '',
                addr_company: this.addressObj.addr_company || '',
                addr_address: this.addressObj.addr_address || '',
                addr_default: this.checked ? 1 : 2,
            }
            if (this.addr_id) {
                params.addr_id = this.addr_id
                updateAddress(params).then((data) => {
                    if (data.status == 200) {
                        this.$message.success(data.msg)
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 800);
                    }
                })
                return
            }
            createAddress(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 800);
                }
            })
        },
    }

}
</script>
  
  
<style scoped>
input[type='checkbox'] {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 1px;
    margin: 0;
    margin-right: 12px;
}

input[type='checkbox']:checked {
    border: 1px solid #018ECF;
    background: url('@/assets/checked1.png') no-repeat center center;
    background-size: 18px 18px;
}

.four-box {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 16px;
}

.left1 {
    width: 232px;
    margin-right: 30px;
}

.box {
    padding: 30px 0;
    width: 1200px;
}

.box1 {
    padding: 0 44px;
}

.one {
    width: 100%;
    height: 52px;
    border-radius: 2px;
    border: 1px solid #DDDDDD;
    margin-bottom: 18px;
    align-items: center;
}

.kuang {
    width: 60px;
}

.icon {
    display: block;
    width: 36px;
    height: 36px;
    margin: 8px auto;
}

input[type='text'],
input[type='password'],
input[type='tel'] {
    background: rgba(0, 0, 0, 0);
    border: none;
    box-sizing: border-box;
    padding: 0 11px;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    outline: none;
}

input[type='text']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder {
    color: #999;
    font-weight: 400;
}

.xian {
    width: 1px;
    height: 37px;
    border-left: 1px solid #DDDDDD;
}

.btn {
    height: 52px;
    background: #018ECF;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 52px;
    text-align: center;
}
</style>