<template>
  <div>
    <div class="head_contain">
      <div class="head_top flex-wrap align-start" >
        <img :src="require('@/assets/logo.png')" fit="cover" class="pointer logo" alt="" @click="toMain()" />
        <div class="flex-con align-center relative">
          <!-- <input type="text" name="" value="" v-model="keyword" :placeholder="$t('headSSCP')" />
          
            <div style="width: 97px;height: 42px;background-color: #008DD1;border-radius: 0pt 4px 4px 0pt;color: #ffffff;line-height: 42px;display: flex;justify-content: center;">
             <div style="width: 32px;height: 42px;">
              <img :src="require('@/assets/search.png')" fit="cover" alt="" class="search_icon pointer"
            @click="fnToCate(0)" />
             </div>
              <div>{{ $t('xjd.ss') }}</div>
            </div> -->
        </div>
        <div class="pointer" @click="fnCenter">
          <span>{{ $t('headGRZX') }}</span>
        </div>
        <div class="">
          <el-dropdown @command="handleCommand1">
            <span class="el-dropdown-link">
              {{ user_name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="jbxx">{{ $t('headGRZXObj.jbxx') }}</el-dropdown-item>
              <el-dropdown-item command="dzgl">{{ $t('headGRZXObj.dzgl') }}</el-dropdown-item>
              <el-dropdown-item command="wdsc">{{ $t('headGRZXObj.wdsc') }}</el-dropdown-item>
              <el-dropdown-item command="0" divided>{{ $t('headGRZXObj.qbdd') }}</el-dropdown-item>
              <el-dropdown-item command="1">{{ $t('headGRZXObj.dqr') }}</el-dropdown-item>
              <el-dropdown-item command="2">{{ $t('headGRZXObj.dfk') }}</el-dropdown-item>
              <el-dropdown-item command="3">{{ $t('headGRZXObj.dfh') }}</el-dropdown-item>
              <el-dropdown-item command="4">{{ $t('headGRZXObj.dsh') }}</el-dropdown-item>
              <el-dropdown-item command="5">{{ $t('headGRZXObj.ywc') }}</el-dropdown-item>
              <el-dropdown-item command="tczh" divided>{{ $t('headGRZXObj.tczh') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="pointer" @click="gocollect()">
          <span>{{ $t('headGRZXObj.wdsc') }}</span>
        </div>
        <div class="head_shop align-start pointer" @click="fnCar">
          <img style="width: 56px; height: 56px" :src="require('@/assets/car.png')" fit="cover" alt="" />
          <span class="shop_num align-center" v-show="shopCarNumber111">{{ shopCarNumber111 || 0 }}</span>
        </div>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="en">English</el-dropdown-item>
            <el-dropdown-item command="es">Español</el-dropdown-item>
            <el-dropdown-item command="tp">Português</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div style="width: 100%;height: 42px;background-color: #008DD1;">
        <div class="head_tab">
        <div class="flex-wrap relative">
          <div class="tab2 align-center pointer" :class="{ active: tab2Index == 3 }">
            <div class="tab2_item" @click="fnCate(3)">{{ $t('headSY') }}</div>
          </div>
          <div class="tab1 align-center" @mouseenter="mouseenterOutFl()" @mouseleave="mouseleaveOutFl()">
            <span>{{ $t('headQYCP') }}</span>
            <div class="tab1_menu_out" @mouseenter="mouseenterOut()" @mouseleave="mouseleaveOut()" v-show="cateShow">
              <div class="tab1_menu">
                <div class="menu_item pointer" @mouseenter="mouseenter(index, item)"  style="margin-top: 24px;"
                  @mouseleave="mouseleave(index, item)" v-for="(item, index) in goodsCate" :key="'first_' + index"
                  @click="fnToCate(1)">
                  {{ lan == 'en' ? item.cate_name_en
          : lan == 'es' ? item.cate_name_es : item.cate_name_tp }}
          <div >
            <div class="linee"></div>
          </div>
          <div class="left1 pointer" style="width: 50%;display: inline-block;vertical-align:top" v-for="(item, index) in item.secondGoodsCate" :key="'second_all_' + index">
           
                    <div class="second_txt"  @click="fnToCate(2, item.cate_id, lan == 'en' ? item.cate_name_en
          : lan == 'es' ? item.cate_name_es : item.cate_name_tp)">{{ lan == 'en' ? item.cate_name_en
          : lan == 'es' ? item.cate_name_es : item.cate_name_tp }}</div>

           <div style="width: 60%;display: inline-block;vertical-align:top;text-align: left;">
            <div v-for="(item1, index) in item.thirdGoodsCate" :key="'third_' + index"
                        class="third_txt pointer" @click.stop="fnToCate(3, item.cate_id, lan == 'en' ? item.cate_name_en
          : lan == 'es' ? item.cate_name_es : item.cate_name_tp, item1.cate_id, lan == 'en' ? item1.cate_name_en
          : lan == 'es' ? item1.cate_name_es : item1.cate_name_tp)">{{ lan == 'en' ? item1.cate_name_en
          : lan == 'es' ? item1.cate_name_es : item1.cate_name_tp }}
                      </div>
           </div>
        </div>
                </div>
              </div>
              <!-- <div class="tab1_menu">
                <div class="menu_item pointer" @mouseenter="mouseenter(index, item)"
                  @mouseleave="mouseleave(index, item)" v-for="(item, index) in goodsCate" :key="'first_' + index"
                  @click="fnToCate(1)">
                  {{ lan == 'en' ? item.cate_name_en
          : lan == 'es' ? item.cate_name_es : item.cate_name_tp }}
                </div>
              </div>
              <div class="item_hover flex-wrap" v-show="showHover">
                <div class="item_hover_left flex-con">
                  <div class="left1 pointer" v-for="(item, index) in secondGoodsCate" :key="'second_all_' + index">
                    <div class="second_txt" @click="fnToCate(2, item.cate_id, lan == 'en' ? item.cate_name_en
          : lan == 'es' ? item.cate_name_es : item.cate_name_tp)">{{ lan == 'en' ? item.cate_name_en
          : lan == 'es' ? item.cate_name_es : item.cate_name_tp }}</div>
                    <div class="font_small1 align-start">
                      <div v-for="(item1, index) in item.thirdGoodsCate" :key="'third_' + index"
                        class="third_txt pointer" @click.stop="fnToCate(3, item.cate_id, lan == 'en' ? item.cate_name_en
          : lan == 'es' ? item.cate_name_es : item.cate_name_tp, item1.cate_id, lan == 'en' ? item1.cate_name_en
          : lan == 'es' ? item1.cate_name_es : item1.cate_name_tp)">{{ lan == 'en' ? item1.cate_name_en
          : lan == 'es' ? item1.cate_name_es : item1.cate_name_tp }}
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="tab2 align-center pointer" :class="{ active: 0 == tab2Index }">
            <div class="tab2_item" @click="fnFwb(7)">
              {{ $t('headGYWM') }}
            </div>
          </div>
          <div class="tab2 align-center pointer" :class="{ active: 1 == tab2Index }">
            <div class="tab2_item" @click="fnFwb(4)">
              {{ $t('headLXWM') }}
            </div>
          </div>
          <div class="tab2 align-center pointer" :class="{ active: 2 == tab2Index }">
            <div class="tab2_item" @click="fnFwb(2)">
              {{ $t('headYSZC') }}
            </div>
          </div>
          <div class="tab2 align-center pointer" :class="{ active: 4 == tab2Index }">
            <div class="tab2_item" @click="fnCate(4)">
              {{ $t('headXJD') }}
            </div>
            <div class="hong" v-show="xjdNum > 0"></div>
          </div>
        </div>
      </div>
      </div>

    </div>

  </div>
</template>

<script>
import { goodsCateList, shopCarNumber, alreadyReadNumber } from "../api";
export default {
  props: {
    num111: {
      Type: Number,
      default: 999,
    }
  },
  data() {
    return {
      lan: localStorage.getItem('lang') || 'en',
      language: '',
      keyword: "",
      cateShow: false,
      tab2Index: -1,
      shopCarNumber111: '',
      isHover: -1,
      goodsCate: [],
      secondGoodsCate: [],
      first_cate_id: "",
      first_cate_name: "",
      showHover: true,
      user_name: JSON.parse(localStorage.getItem("allUserMr")).user_name ,
      xjdNum: 0
    };
  },
  methods: {
    fnToCate(type, cate_id1, cate_id1_name, cate_id2, cate_id2_name) {//type==1 cate_id1为一级分类id,type==2 cate_id1为二级分类id,type==3 cate_id1为二级分类id cate_id2为三级分类id
      // console.log(type + '||' + this.$router.history);
      if (type == 0) {
        if (this.$router.history.current.name == 'cate') {
          this.$bus.$emit('keyword', this.keyword)
        } else {
          this.$router.push({
            path: 'cate',
            query: {
              keyword: this.keyword
            }
          })
        }
      } else {
        if (this.$router.history.current.name == 'cate') {
          this.$bus.$emit('cate', {
            id1: this.first_cate_id,
            id2: cate_id1,
            id3: cate_id2,
            id1_name: this.first_cate_name,
            id2_name: cate_id1_name,
            id3_name: cate_id2_name
          })
          this.cateShow = false;
        } else {
          this.$router.push({
            name: 'cate',
            params: {
              id1: this.first_cate_id,
              id2: cate_id1,
              id3: cate_id2,
              id1_name: this.first_cate_name,
              id2_name: cate_id1_name,
              id3_name: cate_id2_name
            }
          })
        }
      }
    },
    gocollect(){
      this.$router.push({
            name: 'collect'
          })
          
    },
    initCateAlreadyRead() {
      let params = {}
      alreadyReadNumber(params).then((data) => {
        if (data.status == 200) {
          this.xjdNum = data.msg
        }
      })
    },
    exit() {
      localStorage.setItem('allUserMr', '')
      this.$router.push({ name: 'login' })
    },
    initCate() {
      let params = {}
      goodsCateList(params).then((data) => {
        if (data.status == 200) {
          this.goodsCate = data.msg
          console.log(data.msg);
          console.log(777999);
          // this.secondGoodsCate = data.msg[0].secondGoodsCate
        }
      })
    },
    handleCommand(command) {
      this.language = command == 'en' ? 'English' : command == 'es' ? 'Español' : 'Português';
      this.lan = command;
      this.$i18n.locale = command;
      localStorage.setItem('lang', command)
    },
    handleCommand1(command) {
      switch (command) {
        case 'jbxx':
          this.fnCenter()
          break;
        case 'dzgl':
          this.$router.push({
            name: 'myAddress'
          })
          break;
        case 'wdsc':
          this.$router.push({
            name: 'collect'
          })
          break;
        case 'tczh':
          this.exit()
          break;
        default:
          this.$router.push({
            name: 'orderCenter',
            params: {
              id: command
            },
          })
          this.$bus.$emit("orderType", command);
          break;
      }
    },
    fnCar() {
      this.$router.push({
        name: 'car'
      })
    },
    fnCenter() {
      this.$router.push({
        name: 'myInfo'
      })
    },
    fnCate(type) {
      if (type == 3) {
        this.toMain()
      }
      if (type == 4) {
        this.$router.push({
          name: 'inquiry'
        })
      }
    },
    fnCarNum() {
      let params = {}
      shopCarNumber(params).then((data) => {
        if (data.status == 200) {
          this.shopCarNumber111 = data.msg
        }
      })
    },
    mouseenter(index, item) {
      this.isHover = index;
      this.first_cate_id = item.cate_id;
      this.first_cate_name = this.lan == 'en' ? item.cate_name_en
        : this.lan == 'es' ? item.cate_name_es : item.cate_name_tp
      this.secondGoodsCate = this.goodsCate[index].secondGoodsCate
    },
    mouseleave(index, item) {
      this.isHover = -1;
    },
    mouseenterOut() {
      this.showHover = true;
    },
    mouseleaveOut() {
      // this.showHover = false;
    },
    mouseenterOutFl() {
      this.cateShow = true;
      this.initCate()
    },
    mouseleaveOutFl() {
      this.cateShow = false;
    },

  },
  created() {
    this.$bus.$off('keywordGet')
    this.$bus.$off('carNum111')
    this.$bus.$off('userName')
    this.$bus.$off('xjd')
    this.$bus.$on('keywordGet', (val) => {
      this.keyword = val || ''
    })
    this.$bus.$on('carNum111', () => {
      this.fnCarNum()
    })
    this.$bus.$on('userName', (val) => {
      this.user_name = val || ''
    })
    this.$bus.$on('xjd', () => {
      this.initCateAlreadyRead()
    })
  },
  mounted() {
    this.initCate()
    this.initCateAlreadyRead()
    this.fnCarNum()
    if (this.num111 != 999) {
      this.tab2Index = this.num111
    }
    this.language = this.lan == 'en' ? 'English' : this.lan == 'es' ? 'Español' : 'Português';

  },
};
</script>

<style scoped>
.hong {
  width: 6px;
  height: 6px;
  background: #FF0000;
  border-radius: 50%;
  position: relative;
  right: -2px;
  top: -9px;
}

.tab1:hover span,
.tab2.active,
.tab2:hover,
.tab1.active span {
  font-weight: 600;
}

.el-dropdown {
  font-size: 18px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #999;
}

.el-icon-arrow-down {
  font-size: 18px;
}

.head_top {
  background-color: #fff;
  height: 90px;
 width: 1200px;
 margin: auto;
}

.head_top>div,
.head_top>img {
  margin-left: 38px;
}

.head_top .logo {
  width: 274px;
  height: 67px;
  margin-left: 0;
}

/* ///////分割////// */
/* .item_hover_left::-webkit-scrollbar {
  display: none;
} */

.item_hover_left {
  overflow-y: scroll;
  padding: 12px 0;
}

.left1 {
  margin-top: 12px;
}

.item_hover {
  height: 304px;
  width: 834px;
  background: #f0f0f0;
  box-sizing: border-box;
  position: absolute;
  left: 258px;
  top: 0;
  padding: 0 30px;
  z-index: 99;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.tab1_menu_out {
  position: absolute;
  top: 42px;
  left: -60px;
  width: 258px;
  height: 304px;
}

.tab1_menu {
  position: absolute;
  top: 0px;
  left: 50px;
  width: 1000px;
  height:400px;
  background: #fff;
  box-sizing: border-box;
  padding: 8px 0;
  z-index: 99;
  overflow-y: scroll;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

/* .tab1_menu::-webkit-scrollbar {
  display: none;
} */

.tab1_menu::-webkit-scrollbar,
.item_hover_left::-webkit-scrollbar {
  width: 3px;
  border-radius: 2px;
}

.tab1_menu::-webkit-scrollbar-button,
.item_hover_left::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
  display: none;
}

.tab1_menu::-webkit-scrollbar-corner,
.item_hover_left::-webkit-scrollbar-corner {
  background-color: transparent;
}

.tab1_menu::-webkit-scrollbar-thumb,
.item_hover_left::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: rgba(0, 141, 209, .8);
}

.menu_item {
  padding: 4px 36px;
  font-size: 20px;
  font-weight: 400;
  color: #008DD1;
  line-height: 28px;
  
  
}

/* .menu_item:hover {
  font-weight: 500;
  color: #2A76A8;
  background: rgba(42, 118, 168, 0.14);
} */

.second_txt {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
  display: inline-block;
  width: 49%;
}
.second_txt:hover {
  font-weight: 500;
  color: #008DD1;
}

.item_hover_left .left1:first-child {
  margin-top: 0;
}

.third_txt {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
  display: inline-block;
  margin-left: 20px;
}

.third_txt:hover {
  font-weight: 500;
  color: #008DD1;
}

.font_small1 {
  flex-wrap: wrap;
}

.font_small1 div {
  font-weight: 400;
  font-size: 16px;
  color: #666;
  /* float: left; */
  margin-top: 8px;
  line-height: 22px;
  width: calc(100%/4);
}

.head_contain {
  width: 100%;
  min-height: 110px;
  font-size: 16px;
  line-height: 22px;
  color: #008DD1;
  background-color: #ffffff;
}



.blue {
  color: #fff;
  margin-left: 30px;
}


.shop_num {
  min-width: 18px;
  height: 18px;
  padding: 0 4px;
  background: #018ECF;
  border-radius: 9px;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  top: -12px;
  left: -2px;
}

input[type="text"] {
  background: #ffffff;
  border-radius: 4px 0 0 4px;
  border: 1px solid #008DD1;
  box-sizing: border-box;
  height: 42px;
  width: 80%;
  padding: 0 24px;
  color: #333333;
  font-size: 16px;
  outline: none;
}

input[type="text"]::-webkit-input-placeholder {
  color: #999;
}

input[type="text"]:active {
  box-sizing: border-box;
  border: 1px solid #979797;
}



.search_icon {
  width: 20px;
  height: 20px;
  
 
  margin-top: 10px;
}



.head_tab {
  height: 42px;
  background: #008DD1;
  width: 1200px;
  margin: auto;
  color: #fff;
  font-weight: normal;
}

.tab1 {
  height: 42px;
  /* position: relative; */
  margin-right: 62px;
}

.tab2 {
  margin-right: 40px;
  color: #FFFFFF;
}

.order_step {
  margin: auto;
  width: 41px;
  height: 41px;
  font-weight: 500;
  color: #aaaaaa;
  background: #ffffff;
  border: 2px solid #d5d5d5;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.order_step.active {
  color: #ffffff;
  background: #c52b33;
  border: none;
}

.step_xian {
  width: 100%;
  height: 7px;
  background: #d5d5d5;
  position: absolute;
  top: 17px;
  left: -50%;
}

.step_xian.active {
  background: #c52b33;
}

.step_out {
  position: relative;
  width: 100%;
}

.gjz {
  display: block;
  text-decoration: none;
  /* width: 115px;
  height: 32px;
  background: linear-gradient(90deg, ##9B9A95 0%, #dca01e 100%) ;
  border-radius: 4px;
  */
  /* margin-left: 16px;
  text-align: center;
  line-height:32px; */
  font-size: 12px;
  /* position: absolute;
  right: 20px;
  top: 13px; */
  color: #fff;
  margin-right: 10px;
  margin-bottom: 5px;
}

.dp {
  display: block;
  text-decoration: none;
  font-size: 12px;
  /* position: absolute;
  right: 25px;
  top: 50px; */
  color: #fff;
  margin-right: 10px;
}

.head_bg {
  background: #fff;
}
.linee{
  width: 100%;
  height: 1px;
  background-color:#008DD1;
  margin-top: 16px;
}
</style>
