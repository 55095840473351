const Portuguese = {
    hello: 'Olá',
    headSY: 'Página inicial',
    headXJD: 'Folha de consulta',
    headQYCP: 'Produtos da empresa',
    headGYWM: 'Sobre nós',
    headLXWM: 'Contato',
    headYSZC: 'Política de privacidade',
    headSSCP: 'Pesquisa de produtos',
    headGRZX: 'Informações sobre a conta',
    headYY: 'Inglês',
    headXBYY: 'Español',
    headPTYY: 'Português',
    mainCPFL: 'Categorias de produtos',
    mainTJCP: 'Produtos recomendados',
    mainTJCPYH: 'Produtos mais recentes recomendados',
    mainCK: 'Exibir revisão',
    footAll: {
        rmlb: 'Productos mais populares',
        fwzc: 'Política de serviço',
        sjbhzc: 'Política de proteção de dados',
        yszc: 'Política de privacidade',
        gywm: 'Sobre nós',
        gsjs: 'Introdução da Empresa',
        lxwm: 'Coentre em contato conoscontato',
        dz: 'Endereço',
        zb: 'Sede da empresa'
    },
    headGRZXObj: {
        jbxx: 'informações básicas',
        dzgl: 'gerenciamento de endereços',
        wdsc: 'Meu inventário de produtos',
        qbdd: 'Todos os pedidos',
        dqr: 'a serem confirmados',
        dfk: 'A serem pagos',
        dfh: 'A ser enviado',
        dsh: 'To be received',
        ywc: 'Recebido',
        yqx: 'Cancelado',
        tczh: 'Logout',
        zhzx: 'Centro da conta',
        ddzx: 'Central de pedidos',
    },
    jbXX: {
        xing: 'Sobrenome',
        ming: 'primeiro nome',
        dzyx: 'Endereço de e-mail',
        gs: 'Empresa',
        zw: 'Cargo',
        dhhm: 'Número de telefone',
        tj: 'Adicionar',
        mm: 'Senha',
        ggmm: 'Alterar senha',
        ndqmm: 'Senha atual',
        ndxmm: 'nova senha',
        cxsrndxmm: 'reinserir sua senha',
        qsr: 'Por favor, digite',
        lcbyz: 'Duas novas entradas de senha são inconsistentes'
    },
    addressObj: {
        mo: 'Definir como endereço padrão',
        xdz: 'Novo endereço',
        xxdz: 'Endereço detalhado',
        yb: 'Código postal',
        dzgl: 'Gerenciamento de endereços',
        mrdz: 'Endereço padrão',
        qr: 'Confirmação',
        bjdz: 'Editar endereço'
    },
    cateObj: {
        lb: 'Categoria',
        sx: 'Atributo'
    },
    goodsObj: {
        bz: 'Embalagem',
        sl: 'Quantidade',
        xzgg: 'Escolha o grau',
        ljgm: 'Comprar agora',
        zj: 'Total',
        jrgwc: 'Adicionar ao carrinho',
        cpxq: 'Detalhes do produto',
        tjsc: 'Adicionar economia',
        qxsc: 'Cancelar economia',
    },
    carObj: {
        sc: 'Excluir',
        qx: 'Selecionar tudo',
        cpxx: 'Informações sobre o produto',
        cpgg: 'Especificação do produto',
        dj: 'Preço unitário',
        sl: 'Quantidade',
        xj: 'Subtotal',
        gwczj: 'total da cesta',
        je: 'Quantidade',
        tj: 'Enviar',
        xz: 'Selecionar o produto excluído',
        qrsc: 'Confirmar a exclusão',
        qux: 'Cancelar',
        qr: 'Confirmar',
        xzcp: 'Selecionar um produto',
        tjdd: 'Enviar pedido',
        cpqd: 'Lista de produtos',
        tjdz: 'Adicionar endereço',
        qxzdz: 'Choose an address'
    },
    orderObj: {
        ddxx: 'Informações do pedido',
        gg: 'Especificações',
        zt: 'Status',
        cz: 'Operação',
        ddsj: 'Data do pedido',
        ddbh: 'Número do pedido',
        ckdd: 'Exibir ordem',
        qrdd: 'Confirmar pedido',
        qxdd: 'Cancelar pedido',
        qrsh: 'Confirmar o recebimento de mercadorias',
        zlyd: 'Refazer um pedido',
        ddxq: 'Detalhes do pedido',
        qm: 'full name',
        dh: 'Telefone',
        wl: 'Logística',
        w: 'nenhum',
        hwys: 'transporte'
    },
    logon: {
        dl: 'Iniciar sessão',
        dl1: '',
        dl2: 'Fazer um pedido rapidamente',
        dl3: 'Directly connect with the most favorable price',
        dl4: 'Efficient response',
        dl5: 'digite seu endereço de e-mail',
        dl6: 'digite sua senha',
        dl7: 'Esquecer a senha',
        dl8: 'Não tem conta? Registar-se como',
        dl9: 'digite o código de verificação',
        dl10: 'digite novamente a senha',
        dl11: 'envie o código de verificação',
        dl12: 'A verificação falhou',
        dl13: 'Duas senhas inseridas não correspondem',
        dl14: 'Enviar com sucesso',
        dl15: 'Recuperar',
        dl16: 'dados completos',
        dl17: 'digite seu sobrenome',
        dl18: 'Digite seu primeiro nome',
        dl19: 'Digite seu endereço de e-mail',
        dl20: 'Digite seu número de telefone',
        dl21: 'informe o nome da empresa',
        dl22: 'digite seu cargo',
        dl23: 'Em verificação',
        dl24: 'Enviar novamente',
        dl25: 'Enviar',
        dl26: 'Lembrar',
        dl27: 'falha de verificação',
        dl28: 'Na verificação, aguarde pacientemente',
        d129:'Usuário',
        d130:'Senha',
        d131:'primeiro nome',
        d132:'endereço de e-mail',
        d133:'Telefone',
        d134:'Empresa',
        d135:'Posição',
        d136:'Comprador'
    },
    xjd: {
        txxjd: 'Preencher o formulário de inscrição',
        xjdlb: 'Lista de formulários de inscrição',
        xjcp: 'Produtos solicitados',
        pthf: 'Plataforma de resposta',
        lxr: 'Contato',
        lxrxx: 'número de telefone do contato',
        srlxr: 'Inserir contato',
        srlxrxx: 'digite o número de telefone do contato',
        cpsl: 'Digite o nome e a quantidade do produto solicitado',
        myg:'data de chegada ao porto',
        cyg:'Porto de embarque',
        mdg:'Porto de destino',
        ysfs:'Condições de pagamento',
        mysysj:'Termos e condições de negócios',
        qxz:'Selecionar',
        xzbz:'Selecionar embalagem',
        txsl:'Preencher a quantidade',
        xzhb:'Selecione a moeda',
        cgoods:'selecionar o produto',
        scdd:'Gerar pedido',
        addd:'Adicionar',
        tishi:'Lembrar de mim',
        qx:'Cancelar',
        cg:'Determinar',
        tsxx:'Essa operação excluirá permanentemente o registro de modificação, Deseja continuar?',
        sccg:'exclusão bem-sucedida',
        qxsc:'Cancelar exclusão',
        ss:'pesquisar',
        xjdbh:'Consultar número da folha',
        cggsmc:'Nome da empresa',
        cggsdz:'Endereço da empresa',
        cgyxm:'Nome do comprador',
        cgydh:'Número de telefone do comprador',
        yjdz:'Endereço de e-mail',
        dj:'Preço unitário',
        zongjia:'Total',
        dw:'Unidade',
        txdw:'Preencher a unidade',
        cp:'produto',
    }
}
export default Portuguese