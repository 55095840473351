<template>
  <!-- <div style="width:100%;height:100vh;background: #fff;">
    <div class="head_contain">
      <div class="head_top flex-wrap align-start">
        <img :src="require('@/assets/logo.png')" fit="cover" class="pointer logo" alt="" />
        <div class="flex-con"></div>
        <div></div>
      </div>
      <div class="head_tab"></div>
    </div>
    <div class="box">
      <div class="title">{{ $t('logon.dl') }}</div>
      <div class="info">{{ $t('logon.dl1') }}</div>
      <div class="flex-wrap">
        <div class="left flex-con">
          <div class="leftTxt">
            <div style="margin-bottom:23px" class="oneTxt flex-wrap">
              <img :src="require('@/assets/register6.png')" class="icon1">
              <div>{{ $t('logon.dl2') }}</div>
            </div>
            <div style="margin-bottom:23px" class="oneTxt flex-wrap">
              <img :src="require('@/assets/register7.png')" class="icon1">
              <div>{{ $t('logon.dl3') }}</div>
            </div>
            <div class="oneTxt flex-wrap">
              <img :src="require('@/assets/register8.png')" class="icon1">
              <div>{{ $t('logon.dl4') }}</div>
            </div>
          </div>
          <img :src="require('@/assets/register1.png')" class="tu1">
        </div>
        <div class="xian"></div>
        <div class="right flex-con">
          <div class="one flex-wrap">
            <div class="kuang">
              <img :src="require('@/assets/register2.png')" class="icon">
            </div>
            <input type="text"  v-model="email" class="flex-con" :placeholder="$t('logon.dl5')" value="">
          </div>
          <div class="one flex-wrap">
            <div class="kuang">
              <img :src="require('@/assets/register5.png')" class="icon">
            </div>
            <input type="password"  v-model="pwd" class="flex-con" :placeholder="$t('logon.dl6')" value="">
          </div>
          <div class="button">
            <div class="btn pointer" @click="toLogin">{{ $t('logon.dl') }}</div>
          </div>
          <div class="bottom flex-wrap">
            <div class="pointer" @click="fnForgetPwd">{{ $t('logon.dl7') }}?</div>
            <div class="flex-con"></div>
            <div class="pointer" @click="fnRegister">{{ $t('logon.dl8') }}</div>
          </div>
        </div>
      </div>

    </div>
  </div> -->
  <div style="width: 100%;height: 100vh;display: flex;">
    <div class="left">
      <img :src="require('@/assets/logo.png')" fit="cover" class=" logo" alt="" />
    </div>
    <div class="right" style="background-color: #FFFFFF;">
      <el-dropdown @command="handleCommand" style="margin-top: 30px;margin-right: 85px;">
          <span class="el-dropdown-link">
            {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="en">English</el-dropdown-item>
            <el-dropdown-item command="es">Español</el-dropdown-item>
            <el-dropdown-item command="tp">Português</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      
      <div class="box">
      
      <div class="info">{{ $t('logon.dl1') }}</div>
      
      <div class="flex-wrap">
       
       
        <div class="right flex-con">
          <div class="title">{{ $t('logon.dl') }}</div>
          <div style="text-align: left;font-size: 14px;color: #666666;margin-bottom: 12px;">{{ $t('logon.d129') }}</div>
          <div class="one flex-wrap">
          
            <div class="kuang">
              <img :src="require('@/assets/register2.png')" class="icon">
            </div>
           
            <input type="text"  v-model="email" class="flex-con" :placeholder="$t('logon.dl5')" value="">
          </div>
          <div style="text-align: left;font-size: 14px;color: #666666;margin-bottom: 12px;">{{ $t('logon.d130') }}</div>
          <div class="one flex-wrap">
           
            <div class="kuang">
              <img :src="require('@/assets/register5.png')" class="icon">
            </div>
            
            <input type="password"  v-model="pwd" class="flex-con" :placeholder="$t('logon.dl6')" value="">
          </div>
          <div class="pointer" style="text-align: left;" @click="fnForgetPwd">{{ $t('logon.dl7') }}</div>
          <div class="button">
            <div class="btn pointer" @click="toLogin">{{ $t('logon.dl') }}</div>
          </div>
          
          <div class="bottom flex-wrap">
            
            
            <div class="pointer" style="text-align: left;" @click="fnRegister">{{ $t('logon.dl8') }} <span style="color: #008DD1;">{{ $t('logon.d136') }}</span></div>
          </div>
        </div>
      </div>

    </div>
    </div>
  </div>
</template>
  
<script>
import { mrLogin } from '../api'
export default {
  data() {
    return {
        email: '',
        pwd: '',
        language: '',
        lan: localStorage.getItem('lang') || 'en',
    }
  },
  methods: {
    toLogin() {
      if (this.email == '') {
        this.$message.error(this.$t('logon.dl5'))
        return;
      }
      if (this.pwd == '') {
        this.$message.error(this.$t('logon.dl6'));
        return;
      }
      mrLogin({
        email: this.email,
        pwd: this.pwd,
      }).then((data) => {
        if (data.status == 200) {

            if(data.msg.user_type==1){
              localStorage.setItem('allUserMr', JSON.stringify(data.msg))
              this.$router.push({ name: 'main' })
            }else{
              this.$router.push({
                name: 'information',
                params:{type:1,email:data.msg.user_email,status:data.msg.user_status,user_id:data.msg.user_id}
              })
            }
             
        }
      })

    },
    fnRegister() {
      // this.$router.push({
      //   name: 'register'
      // })
      window.open(this.$router.resolve({name: 'register'}).href, '_blank');
    
    },
    fnForgetPwd() {
      // this.$router.push({
      //   name: 'forgetPwd'
      // })
      window.open(this.$router.resolve({name: 'forgetPwd'}).href, '_blank');
    },
    handleCommand(command) {
      this.language = command == 'en' ? 'English' : command == 'es' ? 'Español' : 'Português';
      this.lan = command;
      this.$i18n.locale = command;
      localStorage.setItem('lang', command)
    },
  },
  mounted() {
   
    this.language = this.lan == 'en' ? 'English' : this.lan == 'es' ? 'Español' : 'Português';

  },

}
</script>
  
  
<style scoped>
.head_contain {
  width: 100%;
  min-height: 110px;
  font-size: 16px;
  line-height: 22px;
  color: #008dd1;
}

.head_top {
  background-color: #fff;
  height: 90px;
  padding: 0 60px;
}

.head_top > div,
.head_top > img {
  margin-left: 38px;
}

.logo {
  width: 269px;
  height: 56px;
  margin-left: 60px;
  margin-top: 70px;
}

.head_tab {
  height: 42px;
  background: #008dd1;
  padding: 0 60px;
  color: #fff;
  font-weight: normal;
}

.box {
  width: 725px;
  padding-bottom: 50px;
  padding-top: 45px;
  box-sizing: border-box;
 
  
  box-sizing: border-box;
  padding: 0 58px 0 58px;
}

.title {
  font-size: 22px;
  font-weight: 400;
  color: #333333;
  line-height: 15px;
  text-align: left;
  margin-bottom: 30px;
}

.info {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  line-height: 21px;
  text-align: center;
  margin-bottom: 50px;
}

.tu1 {
  display: block;
  width: 75%;
  height: 114px;
  margin: 22px auto 0;
}

.xian {
  width: 1px;
  border-left: 1px solid #dddddd;
}

.right {
flex: 1;

}

.one {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  margin-bottom: 18px;
}

.kuang {
  width: 65px;
  border-right: 1px solid #dddddd;
}

.icon {
  display: block;
  width: 36px;
  height: 36px;
  margin: 6px auto;
}

input[type="text"],
input[type="password"],
input[type="tel"] {
  background: rgba(0, 0, 0, 0);
  border: none;
  box-sizing: border-box;
  padding: 0 8px;
  color: #333;
  font-size: 15px;
  outline: none;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder {
  color: #999;
}

.getCode {
  line-height: 40px;
  width: 113px;
  background: #018ecf;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.button {
  padding: 0 24px;
}

.btn {
  width: 100%;
  height: 48px;
  background: #008dd1;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  line-height: 48px;
  text-align: center;
  margin-top: 60px;
}

.icon1 {
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 16px;
}



.leftTxt {
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  width: 75%;
  margin: auto;
}

.oneTxt {
  align-items: center;
}

.bottom {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  margin-top: 30px;
}
.left{
  width: 1400px;
  height: 100vh;
  background-image: url('@/assets/loginbg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.el-dropdown {
  font-size: 18px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #999;
}

.el-icon-arrow-down {
  font-size: 18px;
}
</style>