<template>
  <div>
    <Header :num111="4" />
    <div class="w1200">
      <div v-if="!show">
        <div class="font-008DD1-18-500 right pointer" style="margin:20px 0 30px;"  @click="change()">{{
      this.$t('xjd.txxjd') }}</div>
        <div class="i_item font-333-16 align-between m_b20 pointer" v-for="(item, index) in data" :key="index"
          @click="fnDetail(item.order_id, item.order_xjd_read)">
          <div>{{ item.order_xjd_gs_name || '' }}-{{ item.order_xjd_cyg || '' }}-{{ item.order_xjd_mdg || '' }}</div>
          <div class="align-center">
            <div class="hong" v-show="item.order_xjd_read == 1"></div>
            <img :src="require('@/assets/jt_right1.png')" alt="">
          </div>
        </div>
        <div class="align-center m_b20" v-show="total > 0">
          <Page :limit="limit" :totalNum="total" @onPage="changePage" />
        </div>
      </div>
      <div v-else>
        <div class="font-008DD1-18-500 right pointer" style="margin:20px 0 30px;" @click="change()">{{
      this.$t('xjd.xjdlb') }}</div>
      
        <div class="flex-wrap m_b30">
          
          <!-- <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.xjdbh') }}</div>
            <input type="tel" v-model="order_xjd_cyg" :placeholder="$t('jbXX.qsr')" style="width: 95%;">
          </div> -->
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.cggsmc') }}</div>
            <input type="tel" v-model="order_xjd_gs_name" :placeholder="$t('jbXX.qsr')" style="width: 95%;">
          </div>
          <div class="flex-con ">
            <div class="font-666-16 m_b18">{{ $t('xjd.cggsdz') }}</div>
            <input type="text" v-model="order_xjd_gs_address" :placeholder="$t('jbXX.qsr')">
          </div>

        </div>
        <div class="flex-wrap m_b30">
          
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.cgyxm') }}</div>
            <input type="tel" v-model="order_xjd_user_name" :placeholder="$t('jbXX.qsr')" style="width: 95%;">
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.cgydh') }}</div>
            <input type="tel" v-model="order_xjd_user_phone" :placeholder="$t('jbXX.qsr')" style="width: 95%;">
          </div>
          <div class="flex-con ">
            <div class="font-666-16 m_b18">{{ $t('xjd.yjdz') }}</div>
            <input type="text" v-model="order_xjd_user_email" :placeholder="$t('jbXX.qsr')">
          </div>

        </div>
        <div class="flex-wrap m_b30">
          
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.cyg') }}</div>
            <input type="tel" v-model="order_xjd_cyg" :placeholder="$t('jbXX.qsr')" style="width: 95%;">
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.mdg') }}</div>
            <input type="tel" v-model="order_xjd_mdg" :placeholder="$t('jbXX.qsr')" style="width: 95%;">
          </div>
          <div class="flex-con ">
            <div class="font-666-16 m_b18">{{ $t('xjd.myg') }}</div>
            <input type="text" v-model="order_xjd_gettime" :placeholder="$t('jbXX.qsr')">
          </div>

        </div>
        <div class="flex-wrap m_b30">
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.mysysj') }}</div>
            <input type="tel" v-model="order_xjd_mysysj" :placeholder="$t('jbXX.qsr')"" style=" width: 95%;">
          </div>
          <div class="flex-con ">
            <div class="font-666-16 m_b18" style="width: 95%;">{{ $t('xjd.ysfs') }}</div>
            <input type="text" v-model="order_xjd_paytype" :placeholder="$t('jbXX.qsr')">
          </div>
          
          <div class="flex-con">

          </div>


        </div>
        <div class="flex-wrap m_b30">
          <div class="flex-con ">
            <div class="font-666-16 m_b18 ">{{ $t('xjd.cgoods') }}</div>
            <select v-model="selected" class="sel1" @change="handleSelectChange" id="aa">
              <option disabled value="">{{ $t('xjd.qxz') }}</option>
              <option v-for="option in dataa" :key="option.goods_id" :value="option.goods_id">
                {{ $i18n.locale == 'en' ? option.goods_name_en
      : $i18n.locale == 'es' ? option.goods_name_es
        : option.goods_name_tp }}

              </option>
            </select>
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('goodsObj.xzgg') }}</div>
            <select v-model="selected1" class="sel" id="bb">
              <option disabled value="">{{ $t('xjd.qxz') }}</option>
              <option v-for="option1 in datac" :key="option1.spec_value
      " :value="option1.spec_id">
                {{ option1.spec_value }}

              </option>
            </select>
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.xzbz') }}</div>
            <select v-model="selected2" class="sel" id="cc">
              <option disabled value="">{{ $t('xjd.qxz') }}</option>
              <option v-for="option2 in datab" :key="option2.packaging_id

      " :value="option2.packaging_id">
                {{ option2.packaging_name }}

              </option>
            </select>
          </div>
          
          <!-- <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.txsl') }}</div>
            <input id="dd" type="tel" v-model="num" :placeholder="$t('jbXX.qsr')"" style=" width: 96%;">
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.xzhb') }}</div>
            <select v-model="selected4" class="sel" id="ee">
              <option disabled value="">{{ $t('xjd.qxz') }}</option>
              <option v-for="option3 in options" :key="option3" :value="option3">
                {{ option3 }}

              </option>
            </select>
          </div>
          <div class="add" @click="addNew()">{{  $t('xjd.addd') }}</div> -->
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.txsl') }}</div>
            <input id="dd" type="tel" v-model="num" :placeholder="$t('jbXX.qsr')"" style=" width: 96%;">
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.dw') }}</div>
            <input id="dd" type="text" v-model="dw" :placeholder="$t('jbXX.qsr')"" style=" width: 96%;">
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.xzhb') }}</div>
            <select v-model="selected4" class="sel" id="ee">
              <option disabled value="">{{ $t('xjd.qxz') }}</option>
              <option v-for="option3 in options" :key="option3" :value="option3">
                {{ option3 }}

              </option>
            </select>
          </div>
          <!-- <div class="flex-con">
            <div class="font-666-16 m_b12">{{ $t('xjd.dj') }}</div>
            <input id="dd" type="tel" v-model="num" :placeholder="$t('jbXX.qsr')"" style=" width: 96%;">
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.zongjia') }}</div>
            <input id="dd" type="tel" v-model="num" :placeholder="$t('jbXX.qsr')"" style=" width: 96%;">
          </div> -->
          <!-- <div class="add"  @click="addNew()">{{  $t('xjd.addd') }}</div> -->
          <img :src="require('@/assets/add.png')" alt="" class="add1" @click="addNew()">
        </div>
        <!-- <div class="flex-wrap m_b30">
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.txsl') }}</div>
            <input id="dd" type="tel" v-model="num" :placeholder="$t('jbXX.qsr')"" style=" width: 96%;">
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.dw') }}</div>
            <input id="dd" type="text" v-model="dw" :placeholder="$t('jbXX.qsr')"" style=" width: 96%;">
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.xzhb') }}</div>
            <select v-model="selected4" class="sel" id="ee">
              <option disabled value="">{{ $t('xjd.qxz') }}</option>
              <option v-for="option3 in options" :key="option3" :value="option3">
                {{ option3 }}

              </option>
            </select>
          </div>
          <!-- <div class="flex-con">
            <div class="font-666-16 m_b12">{{ $t('xjd.dj') }}</div>
            <input id="dd" type="tel" v-model="num" :placeholder="$t('jbXX.qsr')"" style=" width: 96%;">
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.zongjia') }}</div>
            <input id="dd" type="tel" v-model="num" :placeholder="$t('jbXX.qsr')"" style=" width: 96%;">
          </div> -->
          <!-- <div class="add"  @click="addNew()">{{  $t('xjd.addd') }}</div> -->
          <!-- <img :src="require('@/assets/add.png')" alt="" class="add1" @click="addNew()">
        </div> --> 
        <div class=" m_b30" v-for="(item, index) in list" :key="index">
          <div class="flex-wrap" style="justify-content: flex-start;">
            <div class="flex-con ">
              <div class="font-666-16 m_b18">{{ $t('xjd.cgoods') }}</div>
           <div class="sel3 align-start" >{{ item.goods_id || '' }}</div>
         </div>
         <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('goodsObj.xzgg') }}</div>
           <div class="sel1 align-start" >{{ item.spec_id || '' }}</div>
         </div>
         <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('xjd.xzbz') }}</div>
           <div class="sel1 align-start" >{{ item.packaging_id || '' }}</div>
         </div>
         <div class="flex-con">
              <div class="font-666-16 m_b18">{{ $t('xjd.txsl') }}</div>
            <div class="sel1 align-start" >{{ item.num || '' }}</div>
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.dw') }}</div>
            <div class="sel1 align-start" >{{ item.of_danwei || '' }}</div>
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.xzhb') }}</div>
            <div class="sel1 align-start" >{{ item.moneytype || '' }}</div>
          </div>
          <img :src="require('@/assets/reduce.png')" alt="" class="add1" @click="handleDelete(index)">
          </div>
         
          <!-- <div class="flex-wrap" style="margin-top: 20px;">
            <div class="flex-con">
              <div class="font-666-16 m_b18">{{ $t('xjd.txsl') }}</div>
            <div class="sel1 align-start" >{{ item.num || '' }}</div>
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.dw') }}</div>
            <div class="sel1 align-start" >{{ item.of_danwei || '' }}</div>
          </div>
          <div class="flex-con">
            <div class="font-666-16 m_b18">{{ $t('xjd.xzhb') }}</div>
            <div class="sel1 align-start" >{{ item.moneytype || '' }}</div>
          </div>
          <img :src="require('@/assets/reduce.png')" alt="" class="add1" @click="handleDelete(index)">
          <div class="flex-wrap m_b30" >
            
          </div>
          </div> -->
          
          

        </div>

        <div class="pointer btn align-center" @click="initCommit()">{{ $t('carObj.tj') }}</div>
      </div>

    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Page from '@/components/Page.vue'
import { limit, xunOrderList, commitXunOrder, alreadyRead, getLikeGoods, goodsDetail ,getXunOrder, getXunList} from '../api'
export default {
  components: {
    Header,
    Footer,
    Page
  },
  data() {
    return {
      show: true,
      page: 1,
      limit: limit,
      total: 0,
      data: [],
      xun_username: '',
      num: '',
      xjcp: '',
      selected: '',
      dataa: [],
      datab: [],
      datac: [],
      selected1: '',
      selected2: '',
      options: ['USD', 'EUR'],
      selected4: '',
      order_xjd_myg: '',
      order_xjd_cyg: '',
      order_xjd_mdg: '',
      order_xjd_ysfs: '',
      order_xjd_mysysj: '',
      order_xjd_gs_name:'',
      order_xjd_gs_address:'',
      order_xjd_user_name:'',
      order_xjd_user_phone:'',
      order_xjd_user_email:'',
      order_xjd_gettime:'',
      order_xjd_paytype:'',
      dw:'',

      list:[],
      list1:[]
    }
  },

  methods: {
    fnXun() {

    },
    getGoods() {
      getLikeGoods({

      }).then((data) => {
        if (data.status == 200) {
          this.dataa = data.msg;


        }
      })
    }
    ,
    initData() {
      getXunList({
        page: this.page
      }).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          console.log(data);
          console.log(888);
          
          this.total = data.count
        }
      })
    },
    initCommit() {
     
      if (!this.order_xjd_cyg) {
        this.$message.error(this.$t('xjd.cyg'))
        return;
      }
      if (!this.order_xjd_mdg) {
        this.$message.error(this.$t('xjd.mdg'))
        return;
      }
      if (!this.order_xjd_gs_name) {
        this.$message.error(this.$t('xjd.cggsmc'))
        return;
      }
      if (!this.order_xjd_gs_address) {
        this.$message.error(this.$t('xjd.cggsdz'))
        return;
      }
      if (!this.order_xjd_user_name) {
        this.$message.error(this. $t('xjd.cgyxm'))
        return;
      }
      if (!this.order_xjd_user_phone) {
        this.$message.error(this.$t('xjd.cgydh'))
        return;
      }
      if (!this.order_xjd_user_email) {
        this.$message.error(this.$t('xjd.yjdz'))
        return;
      }
      if (!this.order_xjd_gettime) {
        this.$message.error(this.$t('xjd.myg'))
        return;
      }
      if (!this.order_xjd_paytype) {
        this.$message.error(this.$t('xjd.ysfs'))
        return;
      }
      
      if (!this.order_xjd_mysysj) {
        this.$message.error(this.$t('xjd.mysysj'))
        return;
      }
      if (!this.list.length) {
        this.$message.error(this.$t('xjd.cpsl'))
        return;
      }
      getXunOrder({
        order_xjd_myg: '',
        order_xjd_cyg: this.order_xjd_cyg,
        order_xjd_mdg: this.order_xjd_mdg,
        order_xjd_ysfs: '',
        order_xjd_mysysj: this.order_xjd_mysysj,
        order_xjd_gs_name:this.order_xjd_gs_name,
        order_xjd_gs_address:this.order_xjd_gs_address,
        order_xjd_user_name:this.order_xjd_user_name,
        order_xjd_user_phone:this.order_xjd_user_phone,
        order_xjd_user_email:this.order_xjd_user_email,
        order_xjd_gettime:this.order_xjd_gettime,
        order_xjd_paytype:this.order_xjd_paytype,
        jsoninfo: JSON.stringify(this.list1),
        
      }).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          setTimeout(() => {
            this.show = true
            this.page = 1
            this.initData();
          }, 800);
        }
      })
    },
    handleSelectChange(value) {
      console.log(value);
      let params = {
        goods_id: value.target.value,

      }
      goodsDetail(params).then((data) => {
        if (data.status == 200) {
          this.datab = data.msg.goods_packaging;
          this.datac = data.msg.spec
          console.log(data.msg);

        }
      })
    },
    change(){
     if(this.show){
      this.show = false
     }
     else{
      this.show = true
     }
    },
    changePage(childrenData) {
      this.page = childrenData;
      this.initData();
    },
    fnDetail(idd, status) {
      if (status == 1) {
        alreadyRead({
          order_id: idd
        }).then((data) => {
          if (data.status == 200) {
            this.$bus.$emit('xjd')
            this.$router.push({
              name: 'inquiryXq',
              params: {
                id: idd
              },
            })
          }
        })
      } else {
        this.$router.push({
          name: 'inquiryXq',
          params: {
            id: idd
          },
        })
      }




    },
    addNew(){
      if (!this.selected) {
        this.$message.error(this.$t('xjd.cgoods'))
        return;
      }
      if (!this.selected1) {
        this.$message.error(this.$t('goodsObj.xzgg'))
        return;
      }
      if (!this.selected2) {
        this.$message.error(this.$t('xjd.xzbz'))
        return;
      }
      if (!this.num) {
        this.$message.error(this.$t('xjd.txsl'))
        return;
      }
      if (!this.dw) {
        this.$message.error(this.$t('xjd.txdw'))
        return;
      }
      if (!this.selected4) {
        this.$message.error(this.$t('xjd.xzhb'))
        return;
      }
      const arr1 ={goods_id:this.selected,
        spec_id:this.selected1,
        packaging_id:this.selected2,
        num:this.num,
        moneytyoe:this.selected4,
        of_danwei:this.dw
      }
      var obj = document.getElementById('aa');
     
      var obj1 = document.getElementById('bb');
      var obj2 = document.getElementById('cc');
      var index = obj.selectedIndex ;
      var index1 = obj1.selectedIndex ;
      var index2 = obj2.selectedIndex ;
      var text = obj.options[index].text;
      var text1 = obj1.options[index1].text;
      var text2 = obj2.options[index2].text;
      const arr ={goods_id:text,
        spec_id:text1,
        packaging_id:text2,
        num:this.num,
        moneytype:this.selected4,
        of_danwei:this.dw
      }
      var obj = document.getElementById('aa');
      var index = obj.selectedIndex
      var text = obj.options[index].text;
      console.log(text);
      
      this.list1.push(arr1)
      // let arr = Array.of('goods_id'+':'+this.selected, 'spec_id'+':'+this.selected1, 'packaging_id'+':'+this.selected2, 'num'+':'+this.num, 'moneytype'+':'+this.selected4);
      this.list.push(arr);
      console.log(JSON.stringify(this.list));
      document.getElementById('aa').selectedIndex = -1;
      document.getElementById('aa').value = '';
      
      
      this.num = '';
      this.dw = '' ;
      this.selected2 = ''
      this.selected1 = ''
      
      
    },
    handleDelete(row){
      this.$confirm(this.$t('xjd.tsxx'),this.$t('xjd.tishi'), {
    confirmButtonText: this.$t('xjd.cg'),
    cancelButtonText: this.$t('xjd.qx'),
    type: "warning"
  })
    .then(() => {
      this.list.splice(row, 1);
       
      
      
      this.$message({
        type: "success",
        message:  this.$t('xjd.sccg')
      });
    })
    .catch(() => {
      this.$message({
        type: "info",
        message:  this.$t('xjd.qxsc')
      });
    });

    },
    
  },
  mounted() {
    this.initData();
    this.getGoods()
  },
}
</script>


<style scoped>
.btn {
  width: 300px;
  height: 52px;
  background: #018ecf;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: auto 0 60px auto;
}

input[type="text"],
input[type="tel"],
textarea {
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  padding: 0 12px;
  border: none;
  box-sizing: border-box;
  color: #333;
  font-size: 16px !important;
  outline: none;
  width:95%;
}

input[type="text"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999;
  font-family: inherit;
}

.i_item {
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 12px;
}

.i_item img {
  width: 24px;
  height: 24px;
  display: block;
}

.i_item .hong {
  width: 6px;
  height: 6px;
  background: #ff0000;
  border-radius: 50%;
  margin-right: 12px;
}

.bread {
  height: 45px;
  background: #f8f8f8;
  padding: 0 60px;
}

.box {
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 15px;
  box-sizing: border-box;
  text-align: center;
}

.sel {
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  padding: 0 12px;
  border: none;
  box-sizing: border-box;
  color: #333;
  font-size: 16px !important;
  outline: none;
  width: 96%;
}

.sel1 {
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  padding: 0 12px;
  border: none;
  box-sizing: border-box;
  color: #333;
  font-size: 16px !important;
  outline: none;
  width: 96%;
}
.sel3{
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  padding: 0 12px;
  border: none;
  box-sizing: border-box;
  color: #333;
  font-size: 16px !important;
  outline: none;
  width: 96%;
}

.add {
  width: 60px;
  height: 30px;
  margin-top: 55px;
  background: #018ecf;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
}
.add1{
  width: 20px;
  height: 20px;
  margin-top: 60px;
  margin-right: 20px;
}
</style>