<template>
    <!-- <div style="width:100%;height:100vh;background: #fff;">
        <div class="head_contain">
            <div class="head_top flex-wrap align-start">
                <img :src="require('@/assets/logo.png')" fit="cover" class="pointer logo" alt="" />
                <div class="flex-con"></div>
                <div></div>
            </div>
            <div class="head_tab"></div>
        </div>
        <div class="box">
            <div class="title">{{ $t('logon.dl7') }}</div>
            <div class="info">{{ $t('logon.dl1') }}</div>
            <div class="flex-wrap">
                <div class="left flex-con">
                    <div class="leftTxt">
                        <div style="margin-bottom:23px" class="oneTxt flex-wrap">
                            <img :src="require('@/assets/register6.png')" class="icon1">
                            <div>{{ $t('logon.dl2') }}</div>
                        </div>
                        <div style="margin-bottom:23px" class="oneTxt flex-wrap">
                            <img :src="require('@/assets/register7.png')" class="icon1">
                            <div>{{ $t('logon.dl3') }}</div>
                        </div>
                        <div class="oneTxt flex-wrap">
                            <img :src="require('@/assets/register8.png')" class="icon1">
                            <div>{{ $t('logon.dl4') }}</div>
                        </div>
                    </div>
                    <img :src="require('@/assets/register1.png')" class="tu1">
                </div>
                <div class="xian"></div>
                <div class="right flex-con">
                    <div class="one flex-wrap">
                        <div class="kuang">
                            <img :src="require('@/assets/register2.png')" class="icon">
                        </div>
                        <input type="text" v-model="email" class="flex-con" :placeholder="$t('logon.dl5')" value="">
                    </div>
                    <div class="one flex-wrap">
                        <div class="kuang">
                            <img :src="require('@/assets/register4.png')" class="icon">
                        </div>
                        <input type="text" v-model="sms" class="flex-con" :placeholder="$t('logon.dl9')" value="">
                        <div class="getCode" @click="need_ma()">{{ yzm }}</div>
                    </div>
                    <div class="one flex-wrap">
                        <div class="kuang">
                            <img :src="require('@/assets/register3.png')" class="icon">
                        </div>
                        <input type="password" v-model="pwd" class="flex-con" :placeholder="$t('logon.dl6')" value="">
                    </div>
                    <div class="one flex-wrap">
                        <div class="kuang">
                            <img :src="require('@/assets/register5.png')" class="icon">
                        </div>
                        <input type="password" v-model="pwd1" class="flex-con" :placeholder="$t('logon.dl10')" value="">
                    </div>
                    <div class="button">
                        <div class="btn pointer" @click="fnOver">{{ $t('logon.dl25') }}</div>
                    </div>
                </div>
            </div>

        </div>
    </div> -->
    <div style="width: 100%;height: 100vh;display: flex;">
    <div class="left">
      <img :src="require('@/assets/logo.png')" fit="cover" class=" logo" alt="" />
    </div>
    <div class="right" style="background-color: #FFFFFF;">
        <el-dropdown @command="handleCommand" style="margin-top: 30px;margin-right: 85px;">
          <span class="el-dropdown-link">
            {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="en">English</el-dropdown-item>
            <el-dropdown-item command="es">Español</el-dropdown-item>
            <el-dropdown-item command="tp">Português</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        
      
      <div class="box">
      
      <div class="info">{{ $t('logon.dl1') }}</div>
      
      <div class="flex-wrap">
       
       
        <div class="right flex-con">
            <div class="title">{{ $t('logon.dl7') }}</div>
                    <div class="one flex-wrap">
                        <div class="kuang">
                            <img :src="require('@/assets/register2.png')" class="icon">
                        </div>
                        <input type="text" v-model="email" class="flex-con" :placeholder="$t('logon.dl5')" value="">
                    </div>
                    <div class="one flex-wrap">
                        <div class="kuang">
                            <img :src="require('@/assets/register4.png')" class="icon">
                        </div>
                        <input type="text" v-model="sms" class="flex-con" :placeholder="$t('logon.dl9')" value="">
                        <div class="getCode" @click="need_ma()">{{ yzm }}</div>
                    </div>
                    <div class="one flex-wrap">
                        <div class="kuang">
                            <img :src="require('@/assets/register3.png')" class="icon">
                        </div>
                        <input type="password" v-model="pwd" class="flex-con" :placeholder="$t('logon.dl6')" value="">
                    </div>
                    <div class="one flex-wrap">
                        <div class="kuang">
                            <img :src="require('@/assets/register5.png')" class="icon">
                        </div>
                        <input type="password" v-model="pwd1" class="flex-con" :placeholder="$t('logon.dl10')" value="">
                    </div>
                    <div class="button">
                        <div class="btn pointer" @click="fnOver">{{ $t('logon.dl25') }}</div>
                    </div>
                </div>
      </div>

    </div>
    </div>
  </div>
</template>
  
<script>
import { sendCode,forgetPassword } from '../api'
export default {
  data() {
    return {
      email: '',
      sms: '',
      yzm:this.$t('logon.dl11'),
      isOnclick: true,
      onClickTime: '',
      isinerval: '',
      pwd: '',
      pwd1: '',
      language: '',
      lan: localStorage.getItem('lang') || 'en',
    }
  },
  methods: {
    fnOver() {
    if (this.email == '') {
        this.$message.error(this.$t('logon.dl5'))
        return;
      }
      if (this.sms == '') {
        this.$message.error(this.$t('logon.dl9'));
        return;
      }
      if (!/^\d{6}$/.test(this.sms)) {
        this.$message.error(this.$t('logon.dl12'));
        return;
      }
      if (!this.pwd) {
        this.$message.error(this.$t('logon.dl6'));
        return;
      }
      if (this.pwd != this.pwd1) {
        this.$message.error(this.$t('logon.dl13'));
        return;
      }
      forgetPassword({
        email: this.email,
        code: this.sms,
        pwd: this.pwd,
        verifyPwd: this.pwd1,
      }).then((data) => {
        if (data.status == 200) {
            this.$router.go(-1);
        }
      })

    },
    need_ma() {
      if (this.email == '') {
        this.$message.error(this.$t('logon.dl5'))
        return;
      }
      if (this.isOnclick) {
        //调用接口判断
        sendCode({
          email: this.email,
          type: 2
        }).then((data) => {
          if (data.status == 200) {
            this.$message.success(this.$t('logon.dl14'))
            if (this.isOnclick) {
              this.onClickTime = Math.floor((new Date().getTime()) / 1000)
              this.isinerval = setInterval(this.CountDown, 1000);
              this.isOnclick = false;
            }
          }
        })
      }
    },
    CountDown() {
      this.onClickTime1 = 60 - (Math.floor((new Date().getTime()) / 1000) - this.onClickTime);
      if (this.onClickTime1 < 1) {
        this.yzm = this.$t('logon.dl15');
        clearInterval(this.isinerval);
        this.isOnclick = true;
        return;
      }
      this.yzm = this.onClickTime1 + 's';
    },
    handleCommand(command) {
      this.language = command == 'en' ? 'English' : command == 'es' ? 'Español' : 'Português';
      this.lan = command;
      this.$i18n.locale = command;
      localStorage.setItem('lang', command)
    },
  },
  mounted() {
   
   this.language = this.lan == 'en' ? 'English' : this.lan == 'es' ? 'Español' : 'Português';

 },

}
  </script>
  
  
<style scoped>
.head_contain {
    width: 100%;
    min-height: 110px;
    font-size: 16px;
    line-height: 22px;
    color: #008DD1;
}

.head_top {
    background-color: #fff;
    height: 90px;
    padding: 0 60px;
}

.head_top>div,
.head_top>img {
    margin-left: 38px;
}

 .logo {
    width: 269px;
  height: 56px;
  margin-left: 60px;
  margin-top: 70px;
}

.head_tab {
    height: 42px;
    background: #008DD1;
    padding: 0 60px;
    color: #fff;
    font-weight: normal;
}

.box {
    width: 725px;
  padding-bottom: 50px;
  padding-top: 45px;
  box-sizing: border-box;
 
  margin: 110px auto;
  box-sizing: border-box;
  padding: 0 58px 0 58px;
}

.title {
    font-size: 22px;
  font-weight: 400;
  color: #333333;
  line-height: 15px;
  text-align: left;
  margin-bottom: 30px;
}

.info {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 21px;
    text-align: center;
    margin-bottom: 50px;
}

.tu1 {
    display: block;
    width: 75%;
    height: 114px;
    margin: 22px auto 0;
}

.xian {
    width: 1px;
    border-left: 1px solid #DDDDDD;
}

.right {
   flex: 1;
}

.one {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #DDDDDD;
    margin-bottom: 18px;
}

.kuang {
    width: 65px;
    border-right: 1px solid #DDDDDD;
}

.icon {
    display: block;
  width: 36px;
  height: 36px;
  margin: 6px auto;
}

input[type='text'],
input[type='password'],
input[type='tel'] {
    background: rgba(0, 0, 0, 0);
    border: none;
    box-sizing: border-box;
    padding: 0 8px;
    color: #333;
    font-size: 15px;
    outline: none;
}

input[type='text']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder {
    color: #999;
}

.getCode {
    line-height:48px;
    width: 113px;
    background: #018ECF;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
}

.button {
    padding: 0 24px;
}

.btn {
    width: 100%;
    height: 48px;
    background: #008DD1;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 48px;
    text-align: center;
    margin-top: 60px;
}

.icon1 {
    display: block;
    width: 25px;
    height: 25px;
    margin-right: 16px;
}



.leftTxt {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    width: 75%;
    margin: auto;
}

.oneTxt {
    align-items: center;
}
.bottom {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  margin-top: 30px;
}
.left{
  width: 1400px;
  height: 100vh;
  background-image: url('@/assets/loginbg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.el-dropdown {
  font-size: 18px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #999;
}

.el-icon-arrow-down {
  font-size: 18px;
}
</style>