<template>
  <div>
    <div class="font-018ECF-20-500 align-center h48 bg-F4F4F4">{{ $t('headGRZXObj.zhzx') }}</div>
    <div class="box">
      <div class="pointer" :class="{ active: num == 6 }" @click="fnOpen(6)">{{ $t('headGRZXObj.jbxx') }}</div>
      <div class="pointer" :class="{ active: num == 7 }" @click="fnOpen(7)">{{ $t('headGRZXObj.dzgl') }}</div>
      <div class="pointer" :class="{ active: num == 8 }" @click="fnOpen(8)">{{ $t('headGRZXObj.wdsc') }}</div>
    </div>
    <div class="font-018ECF-20-500 align-center h48 bg-F4F4F4">{{ $t('headGRZXObj.ddzx') }}</div>
    <div class="box">
      <div class="pointer" :class="{ active: num == 0 }" @click="fnOpen(0)">{{ $t('headGRZXObj.qbdd') }}</div>
      <div class="pointer" :class="{ active: num == 1 }" @click="fnOpen(1)">{{ $t('headGRZXObj.dqr') }}</div>
      <div class="pointer" :class="{ active: num == 2 }" @click="fnOpen(2)">{{ $t('headGRZXObj.dfk') }}</div>
      <div class="pointer" :class="{ active: num == 3 }" @click="fnOpen(3)">{{ $t('headGRZXObj.dfh') }}</div>
      <div class="pointer" :class="{ active: num == 4 }" @click="fnOpen(4)">{{ $t('headGRZXObj.dsh') }}</div>
      <div class="pointer" :class="{ active: num == 5 }" @click="fnOpen(5)">{{ $t('headGRZXObj.ywc') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    num111: {
      Type: Number,
      default: 999,
    },
  },
  data() {
    return {
      num: -1
    }
  },
  methods: {
    fnOpen(type) {
      if (type == 6) {
        this.$router.push({
          name: 'myInfo'
        })
      }
      if (type == 7) {
        this.$router.push({
          name: 'myAddress'
        })
      }
      if (type == 8) {
        this.$router.push({
          name: 'collect'
        })
      }
      if (type < 6) {
        this.num = type
        this.$router.push({
          name: 'orderCenter',
          params: {
            id: type
          },
        })
        this.$emit('update', type)
      }
    }
  },
  created() {
    this.$bus.$off("orderType")
    this.$bus.$on("orderType", (val) => {
      this.num = val || "";
      console.log(val);
    });
  },
  mounted() {
    if (this.num111 != 999) {
      this.num = this.num111
    } else {
      let can = this.$route.params.id
      this.num = can
    }
  }
}
</script>


<style scoped>
.box {
  padding: 0 0 26px 32px;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
}

.box>div {
  margin-top: 16px;
}

.box>div:hover,
.box>div.active {
  font-weight: 500;
  color: #333333;
}
</style>