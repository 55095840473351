import { render, staticRenderFns } from "./inquiryXq.vue?vue&type=template&id=c2bc98c2&scoped=true"
import script from "./inquiryXq.vue?vue&type=script&lang=js"
export * from "./inquiryXq.vue?vue&type=script&lang=js"
import style0 from "./inquiryXq.vue?vue&type=style&index=0&id=c2bc98c2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2bc98c2",
  null
  
)

export default component.exports