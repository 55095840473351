const English = {
    hello: 'Hello',
    headXJD: 'Inquiry Sheet',
    headSY: 'Homepage',
    headQYCP: 'Company Products',
    headGYWM: 'About US',
    headLXWM: 'Contact US',
    headYSZC: 'Private Policy',
    headSSCP: 'Product Search',
    headGRZX: 'Account Information',
    headYY: 'English',
    headXBYY: 'Español',
    headPTYY: 'Português',
    mainCPFL: 'Product Category',
    mainTJCP: 'Recommended  products',
    mainTJCPYH: 'Recommended the latest favorable  products',
    mainCK: 'Review',
    footAll: {
        rmlb: 'Hot Products',
        fwzc: 'Service Policy',
        sjbhzc: 'Data Protection Policy',
        yszc: 'Private Policy',
        gywm: 'About US',
        gsjs: 'Company Profile',
        lxwm: 'Contact US',
        dz: 'address',
        zb: 'headquarters'
    },
    headGRZXObj: {
        jbxx: 'Basic Information',
        dzgl: 'Address Management',
        wdsc: 'My Product Inventory',
        qbdd: 'All purchase orders',
        dqr: 'To be confirmed',
        dfk: 'To be paid',
        dfh: 'To be shipped',
        dsh: 'To be received',
        ywc: 'Received ',
        yqx: 'Cancelled ',
        tczh: ' Logout ',
        zhzx: 'Account Center',
        ddzx: ' Order Center',
    },
    jbXX: {
        xing: 'Family Name',
        ming: 'Surname',
        dzyx: 'Email',
        gs: 'Company',
        zw: ' Position',
        dhhm: 'Telephone Number',
        tj: 'Add',
        mm: 'Password',
        ggmm: 'Change password ',
        ndqmm: 'Current password',
        ndxmm: 'New password',
        cxsrndxmm: 'Re-enter your password',
        qsr: 'Please enter',
        lcbyz: 'Two new password inputs are inconsistent'
    },
    addressObj: {
        mo: 'Set as default address',
        xdz: 'New address',
        xxdz: 'Detailed address',
        yb: 'Post Code',
        dzgl: 'Address management',
        mrdz: 'Default address',
        qr: 'Confirmation',
        bjdz: 'Edit address'
    },
    cateObj: {
        lb: 'Category ',
        sx: 'Attribute'
    },
    goodsObj: {
        bz: 'Packing ',
        sl: 'Quantity',
        xzgg: 'Choose grade',
        ljgm: 'Buy	now',
        zj: 'Total',
        jrgwc: 'Add to shopping cart',
        cpxq: 'Product Details',
        tjsc: 'Add Saving',
        qxsc: 'Cancel Saving',
    },
    carObj: {
        sc: 'Delete  ',
        qx: 'Select all',
        cpxx: 'Product Information',
        cpgg: 'Product Specification',
        dj: ' Unit price',
        sl: 'Quantity',
        xj: 'Subtotal',
        gwczj: 'Total Shopping Cart',
        je: ' Amount',
        tj: 'Submit',
        xz: 'Please select the deleted product',
        qrsc: 'Confirm deletion',
        qux: 'Cancel',
        qr: 'Confirmation',
        xzcp: 'Please select a product',
        tjdd: 'Submit order',
        cpqd: 'Product List ',
        tjdz: 'Add address',
        qxzdz: 'Please choose address'
    },
    orderObj: {
        ddxx: 'Order information',
        gg: 'Specification',
        zt: 'Status',
        cz: 'Operation',
        ddsj: 'Order date',
        ddbh: 'Order number',
        ckdd: 'View order',
        qrdd: 'Confirm order',
        qxdd: 'Cancel order',
        qrsh: 'Confirming receipt of goods',
        zlyd: 'Please place an order again ',
        ddxq: 'Order details',
        qm: 'full name',
        dh: 'Telephone',
        wl: 'Logistics',
        w: 'None',
        hwys: 'Transportation'
    },
    logon: {
        dl: 'Login',
        dl1: '',
        dl2: 'Place an order quickly',
        dl3: 'Directly connect with the most favorable price',
        dl4: 'Efficient recovery',
        dl5: 'Please enter email',
        dl6: ' Please enter password',
        dl7: 'Forget password',
        dl8: 'Do not have an account? Register as a',
        dl9: 'Please enter verification code',
        dl10: 'Please enter password again',
        dl11: 'Please send verification code',
        dl12: 'Verification error',
        dl13: 'Two input passwords are inconsisten',
        dl14: 'Send successfully',
        dl15: 'Retrieve',
        dl16: 'Complete data',
        dl17: 'Please enter your surname',
        dl18: ' Please enter your name',
        dl19: ' Please enter your email',
        dl20: 'Please enter telephone number',
        dl21: 'Please enter company name ',
        dl22: 'Please enter position',
        dl23: 'In verification',
        dl24: 'Submit again',
        dl25: 'Submit',
        dl26: 'Remind',
        dl27: 'Verification failure',
        dl28: 'In verification, please wait patiently',
        d129:'User',
        d130:'Password',
        d131:'Name ',
        d132:'Email ',
        d133:'Telephone',
        d134:'Company ',
        d135:'Position ',
        d136:'Buyer'
    },
    xjd: {
        txxjd: 'Fill in inquiry sheet',
        xjdlb: 'Inquiry sheet list ',
        xjcp: 'Inquired products',
        pthf:'Platform reply',
        lxr:'Contact',
        lxrxx:'Contact phone number',
        srlxr:'Enter contact',
        srlxrxx:'Enter contact phone number',
        cpsl:'Fill in inquired product name and  quantity',
        myg:'Arrival date at port',
        cyg:'Shipment Port',
        mdg:'Destination Port',
        ysfs:'Payment terms',
        mysysj:'Trade Terms',
        qxz:'Please select',
        xzbz:'Select Packing',
        txsl:'Fill in quantity',
        xzhb:'Select Currency ',
        cgoods:'Select Product',
        scdd:'Generate Order',
        addd:'Add',
        tishi:'Remind',
        qx:'Cancel',
        cg:'Determine',
        tsxx:'This operation will permanently delete the change record. Do you want to continue?',
        sccg:'Successfully deleted',
        qxsc:'Cancel deletion',
        ss:'Search',
        xjdbh:'Inquiry Sheet Number',
        cggsmc:'Company Name',
        cggsdz:'Company Address',
        cgyxm:' Buyer’s Name',
        cgydh:'Buyer’s Telephone',
        yjdz:'Email',
        dj:'Unit Price',
        zongjia:'Total Price',
        dw:'Unit',
        txdw:'Fill in Unit',
        cp:'Product',


       

        
    }
}
export default English