<template>
    <div class="bg-fff">
        <Header />
        <div class="flex-wrap box margin">
            <div class="left1">
                <MyInfo :num111="6" />
            </div>
            <div class="flex-con four-box">
                <div class="font-333-20-500" style="margin-bottom:12px;">
                    <div>{{ $t('headGRZXObj.jbxx') }}</div>
                </div>
                <div class="box1">
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.ming') + ' *'"
                            v-model="baseInfo.user_name" value="">
                    </div>
                  
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.dzyx') + ' *'"
                            v-model="baseInfo.user_email" value="" readonly>
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.gs') + ' *'"
                            v-model="baseInfo.user_company" value="">
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.zw') + ' *'"
                            v-model="baseInfo.user_position" value="">
                    </div>
                    <div class="one flex-wrap">
                        <div class="kuang">
                            <img :src="require('@/assets/phone.png')" class="icon">
                        </div>
                        <div class="xian"></div>
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.dhhm') + ' *'"
                            v-model="baseInfo.user_phone" value="">
                    </div>
                    <div class="btn pointer" @click="initCommitCheck">{{ $t('jbXX.tj') }}</div>
                    <div class="font-333-20-500" style="margin-top:28px;margin-bottom:12px;">
                        <div>{{ $t('jbXX.mm') }}</div>
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.ndqmm') + ' *'"
                            v-model="pwdObj.user_password" value="">
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.ndxmm') + ' *'" v-model="pwdObj.pwd"
                            value="">
                    </div>
                    <div class="one flex-wrap">
                        <input type="text" class="flex-con" :placeholder="$t('jbXX.cxsrndxmm') + ' *'"
                            v-model="pwdObj.verifyPwd" value="">
                    </div>
                    <div class="btn pointer" @click="initChangePwd">{{ $t('jbXX.ggmm') }}</div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import { userBaseInfo, commitCheck, changePwd } from '../api'
export default {
    components: {
        Header,
        Footer,
        MyInfo
    },
    data() {
        return {
            baseInfo: {},
            pwdObj: {}
        }
    },
    mounted() {
        this.initBaseInfo()
    },
    methods: {
        initBaseInfo() {
            let params = {}
            userBaseInfo(params).then((data) => {
                if (data.status == 200) {
                    this.baseInfo = data.msg
                }
            })
        },
        initCommitCheck() {
            if (!this.baseInfo.user_name) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.ming'))
                return;
            }
           
            if (!this.baseInfo.user_email) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.dzyx'))
                return;
            }
            if (!this.baseInfo.user_company) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.gs'))
                return;
            }
            if (!this.baseInfo.user_position) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.zw'))
                return;
            }
            if (!this.baseInfo.user_phone) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.dhhm'))
                return;
            }

            let params = {
                user_email: this.baseInfo.user_email || '',
                user_xing: this.baseInfo.user_xing || '',
                user_name: this.baseInfo.user_name || '',
                user_phone: this.baseInfo.user_phone || '',
                user_company: this.baseInfo.user_company || '',
                user_position: this.baseInfo.user_position || '',
            }
            commitCheck(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                    this.$bus.$emit("userName", this.baseInfo.user_name + this.baseInfo.user_xing);
                }
            })
        },
        initChangePwd() {
            if (!this.pwdObj.user_password) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.ndqmm'))
                return;
            }
            if (!this.pwdObj.pwd) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.ndxmm'))
                return;
            }
            if (!this.pwdObj.verifyPwd) {
                this.$message.error(this.$t('jbXX.qsr') + this.$t('jbXX.cxsrndxmm'))
                return;
            }
            if (this.pwdObj.verifyPwd != this.pwdObj.pwd) {
                this.$message.error(this.$t('jbXX.lcbyz'))
                return;
            }
            let params = {
                oldPwd: this.pwdObj.user_password || '',
                pwd: this.pwdObj.pwd || '',
                verifyPwd: this.pwdObj.verifyPwd || ''
            }
            changePwd(params).then((data) => {
                if (data.status == 200) {
                    this.$message.success(data.msg)
                }
            })
        },
    }

}
</script>


<style scoped>
.four-box {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 16px;
}

.left1 {
    width:232px;
    margin-right: 30px;
}

.box {
    padding: 30px 0;
    width: 1200px;
}

.box1 {
    padding: 0 44px;
}

.one {
    width: 100%;
    height: 52px;
    border-radius: 2px;
    border: 1px solid #DDDDDD;
    margin-bottom: 18px;
    align-items: center;
}

.kuang {
    width: 60px;
}

.icon {
    display: block;
    width: 36px;
    height: 36px;
    margin: 8px auto;
}

input[type='text'],
input[type='password'],
input[type='tel'] {
    background: rgba(0, 0, 0, 0);
    border: none;
    box-sizing: border-box;
    padding: 0 11px;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    outline: none;
}

input[type='text']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder {
    color: #999;
    font-weight: 400;
}

.xian {
    width: 1px;
    height: 37px;
    border-left: 1px solid #DDDDDD;
}

.btn {
    height: 52px;
    background: #018ECF;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 52px;
    text-align: center;
}
</style>