<template>
    <div class="bg-fff">
        <Header />
        <div class="box margin">
            <div class="flex-wrap align-between center box2 font-333-20-500">
                <div style="width:104px;" class="left align-start">
                    <input type="checkbox" name="allCheckbox" value="" id="allCheck" @change="allCheck" />
                    {{ $t('carObj.qx') }}
                </div>
                <div class="flex-con left">{{ $t('carObj.cpxx') }}</div>
                <div style="width:180px;margin-right:20px;">{{ $t('carObj.cpgg') }}</div>
                <div style="width:144px;">{{ $t('carObj.dj') }}</div>
                <div style="width:180px;margin:0 30px;">{{ $t('carObj.sl') }}</div>
                <div style="width:134px;">{{ $t('carObj.xj') }}</div>
            </div>
            <div class="four-box">
                <div class="order-item m_t12" v-for="(item, index) in ShopCarListData" :key="item.sc_id">
                    <div class="box5 flex-wrap align-between center font-333-18">
                        <div style="width:104px;" class="align-start">
                            <input type="checkbox" name="checkbox" value="" class="checkbox" @change="oneCheck" @click.stop
                                :data-price="((item.spec_cu_price * item.sc_number * 100) / 100).toFixed(2)
                                    " :data-idd="item.sc_id" :id="'check_' + item.sc_id" />
                        </div>
                        <div class="flex-con left">
                            <div class="align-start">
                                <img :src="item.goods_thumb" alt="">
                                <div class="font-333-18-500" style="width:215px;">{{ $i18n.locale == 'en' ?
                                    item.goods_name_en
                                    : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
                            </div>
                        </div>
                        <div style="width:180px;margin-right:20px;">
                            {{ item.packaging_name }} <br> {{ item.spec_value }}
                        </div>
                        <div class="" style="width:144px;">
                            $ {{ item.spec_cu_price }}
                        </div>
                        <div style="width:180px;margin:0 30px;">
                            <el-input-number v-model="item.sc_number" @change="(val) => {
                                handleChange(
                                    val,
                                    item.sc_id,
                                    index,
                                    ((item.spec_cu_price * item.sc_number * 100) / 100).toFixed(2)
                                )
                            }
                                " :min="1"></el-input-number>
                        </div>
                        <div style="width:134px;">
                            $ {{
                                ((item.spec_cu_price * item.sc_number * 100) / 100).toFixed(2)
                            }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="align-between-top m_t28">
                <div class="btn1 align-center pointer" @click="fnDelete">{{ $t('carObj.sc') }}</div>
                <div class="box3">
                    <div class="font-333-20-500 m_b10">{{ $t('carObj.gwczj') }}</div>
                    <div class="align-between font-333-18 m_b20">
                        <div>{{ $t('carObj.je') }}</div>
                        <div>$ {{ allPrice || 0.00 }}</div>
                    </div>
                    <div class="btn2 align-center pointer" @click="fnPay">{{ $t('carObj.tj') }}</div>
                </div>
            </div>

            <el-dialog :visible.sync="dialogVisible" width="300px" :show-close="false">
                <div class="dialog">
                    <div class="font-333" style="text-align: center; font-size: 20px">
                        {{ $t('carObj.qrsc') }}？
                    </div>
                    <div class="align-around" style="margin-top: 40px">
                        <div class="btn222 btn111 align-center pointer" @click="dialogVisible = false">
                            {{ $t('carObj.qux') }}
                        </div>
                        <div class="btn222 align-center pointer" @click="fnCun">{{ $t('carObj.qr') }}</div>
                    </div>
                </div>
            </el-dialog>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { shopCarList, setNumber, delShopCar } from '../api'
export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            dialogVisible: false,
            ShopCarListData: [],
            sc_ids: '',
            allPrice: ''
        }
    },
    mounted() {
        this.initShopCarList()
    },
    methods: {
        fnPay() {
            let sc_id = []
            let allCk2 = document.querySelectorAll("input[name='checkbox']:checked")
            allCk2.forEach((element) => {
                sc_id.push(element.dataset.idd)
            })
            this.sc_ids = sc_id.join(',')
            if (!this.sc_ids) {
                this.$message.error(this.$t('carObj.xzcp'))
                return
            }
            this.$router.push({
                path: 'sureOrder',
                query: {
                    type: 1,
                    sc_ids: this.sc_ids
                },
            })
        },
        fnCun() {
            let params = {
                sc_ids: this.sc_ids,
            }
            delShopCar(params).then((data) => {
                if (data.status == 200) {
                    this.dialogVisible = false
                    this.$message.success(data.msg)
                    this.initShopCarList()
                    this.$bus.$emit('carNum111')
                }
            })
        },
        fnDelete() {
            let sc_id = []
            let allCk2 = document.querySelectorAll("input[name='checkbox']:checked")
            allCk2.forEach((element) => {
                sc_id.push(element.dataset.idd)
            })
            this.sc_ids = sc_id.join(',')
            if (!this.sc_ids) {
                this.$message.error(this.$t('carObj.xz'))
                return
            }
            this.dialogVisible = true
        },
        initShopCarList() {
            let params = {}
            shopCarList(params).then((data) => {
                if (data.status == 200) {
                    this.ShopCarListData = data.msg
                }
            })
        },
        oneCheck(e) {
            // console.log(e.target.checked)
            let allCk1 = document.querySelectorAll("input[name='checkbox']")
            let allCk2 = document.querySelectorAll("input[name='checkbox']:checked")
            if (allCk1.length == allCk2.length && allCk2.length > 0) {
                document.querySelector('#allCheck').checked = true
            } else if (allCk1.length != allCk2.length) {
                document.querySelector('#allCheck').checked = false
            }
            this.allPrice = 0
            for (let index = 0; index < allCk2.length; index++) {
                this.allPrice = ((Number(allCk2[index].dataset.price) * 100 + Number(this.allPrice) * 100) / 100).toFixed(2)
            }
        },
        allCheck(e) {
            let allCk = document.querySelectorAll("input[name='checkbox']")
            if (allCk.length > 0) {
                this.allPrice = 0
                allCk.forEach((element) => {
                    if (e.target.checked) {
                        element.checked = true
                        this.allPrice = ((Number(element.dataset.price) * 100 + Number(this.allPrice) * 100) / 100).toFixed(2)
                    } else {
                        element.checked = false
                    }
                })
            }
        },
        handleChange(value, id, index, price) {
            let params = {
                number: value,
                sc_id: id,
            }
            setNumber(params).then((data) => {
                if (data.status == 200) {
                    this.$bus.$emit('carNum111')
                    this.ShopCarListData[index].sc_number = value
                    if (document.getElementById('check_' + id).checked) {
                        let allCk2 = document.querySelectorAll(
                            "input[name='checkbox']:checked"
                        )
                        this.allPrice = 0
                        allCk2.forEach((element) => {
                            this.allPrice = ((Number(element.dataset.price) * 100 + Number(this.allPrice) * 100) / 100).toFixed(2)
                        })
                    }
                }
            })
        }
    }

}
</script>
  
<style scoped>
.btn2 {
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    background: #018ECF;
    border-radius: 4px;
    height: 59px;
}

.btn1 {
    width: 249px;
    height: 59px;
    border-radius: 4px;
    border: 1px solid #979797;
}

input[type='checkbox'] {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #979797;
    border-radius: 2px;
    margin-right: 16px;
}

input[type='checkbox']:checked {
    border: 2px solid #018ECF;
    background: url('@/assets/checked1.png') no-repeat center center;
    background-size: 18px 18px;
}

.box2 {
    height: 48px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 0 12px;
    margin-bottom: 30px;
}

.box5 {
    padding: 10px 12px 12px;
}

.box5 img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    margin-right: 10px;
}

.order-item {}

.box3 {
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #979797;
    min-width: 550px;
}

.four-box {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.box {
    padding: 30px 0 50px;
    width: 1200px;
}

/* 加减数 */
::v-deep .el-input-number {
    width: 100%;
    height: 42px;
    border: none;
    line-height: 42px;
}

::v-deep .el-input-number__increase {
    border-left: none;
    width: 42px;
    height: 42px;
    background: none;
    font-size: 18px;
}

::v-deep .el-input-number__decrease {
    background: none;
    border-right: none;
    width: 42px;
    height: 42px;
    font-size: 18px;
}

::v-deep .el-input-number .el-input {
    height: 42px;
}

::v-deep .el-input-number .el-input__inner {
    padding-left: 0;
    padding-right: 0;
    border: none;
    height: 42px;
    font-size: 18px;
    border: 1px solid #979797;
}

::v-deep .el-input__inner {
    border-radius: 28px;
    background: #F7F7F7;
}
</style>