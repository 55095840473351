const Spanish = {
    hello: 'Hola',
    headSY: 'Página de inicio',
    headXJD:'Hoja de consulta',
    headQYCP: 'Productos de la empresa',
    headGYWM: 'Sobre nosotros',
    headLXWM: 'Contacto',
    headYSZC: 'Política de privacidad',
    headSSCP: 'Búsqueda de productos',
    headGRZX: 'Información de la cuenta',
    headYY: 'English',
    headXBYY: 'Español',
    headPTYY: 'Português',
    mainCPFL: 'Categoría de productos',
    mainTJCP: 'Productos recomendados',
    mainTJCPYH: 'Últimos productos más recomendados',
    mainCK: 'Ver Reseña',
    footAll: {
        rmlb: 'Productos más populares',
        fwzc: 'Política de servicio',
        sjbhzc: 'Política de protección de datos',
        yszc: 'Política de privacidad',
        gywm: 'Sobre nosotros',
        gsjs: 'Presentación de la empresa',
        lxwm: 'Contacto',
        dz: 'Dirección',
        zb: 'Sede Central'
    },
    headGRZXObj: {
        jbxx: 'Información básica',
        dzgl: 'Gestión de direcciones',
        wdsc: 'Mi inventario de productos',
        qbdd: 'Todos los pedidos',
        dqr: 'A confirmar',
        dfk: 'Por pagar',
        dfh: 'Por enviar',
        dsh: 'Por recibir',
        ywc: 'Recibido',
        yqx: 'Cancelado',
        tczh: 'Cerrar sesión',
        zhzx: 'Centro de cuentas',
        ddzx: 'Centro de pedidos',
    },
    jbXX: {
        xing: 'Apellido',
        ming: 'Nombre',
        dzyx: 'Correo electrónico',
        gs: 'Empresa',
        zw: 'Cargo',
        dhhm: 'Número de teléfono',
        tj: 'Añadir',
        mm: 'Contraseña',
        ggmm: 'Cambiar contraseña',
        ndqmm: 'Contraseña actual',
        ndxmm: 'Nueva contraseña',
        cxsrndxmm: 'Vuelva a introducir su contraseña',
        qsr: 'Por favor, entre ',
        lcbyz: 'Dos nuevas entradas de contraseña son incoherentes'
    },
    addressObj: {
        mo: 'Establecer como dirección por defecto',
        xdz: 'nueva dirección',
        xxdz: 'Dirección detallada',
        yb: 'Código postal',
        dzgl: 'gestión de direcciones',
        mrdz: 'Dirección por defecto',
        qr: 'Confirmación',
        bjdz: 'Editar dirección'
    },
    cateObj: {
        lb: 'Categoría',
        sx: 'Atributo'
    },
    goodsObj: {
        bz: 'Embalaje',
        sl: 'Cantidad',
        xzgg: 'Elegir grado',
        ljgm: 'Comprar ahora',
        zj: 'Total',
        jrgwc: 'Añadir a la cesta',
        cpxq: 'Detalles del producto',
        tjsc: 'Añadir ahorro',
        qxsc: 'Cancelar ahorro',
    },
    carObj: {
        sc: 'Borrar',
        qx: 'Seleccionar todo',
        cpxx: 'Información del producto',
        cpgg: 'Especificación del producto',
        dj: 'Precio unitario',
        sl: 'Cantidad',
        xj: 'Subtotal',
        gwczj: 'Total de la cesta',
        je: 'Importe',
        tj: 'Enviar',
        xz: 'Seleccione el producto eliminado',
        qrsc: 'Confirmar eliminación',
        qux: 'Cancelar',
        qr: 'Confirmar',
        xzcp: 'Seleccione un producto',
        tjdd: 'Enviar pedido',
        cpqd: 'Lista de productos',
        tjdz: 'Añadir dirección',
        qxzdz: 'Elija una dirección'
    },
    orderObj: {
        ddxx: 'Información del pedido',
        gg: 'Especificaciones',
        zt: 'Estado',
        cz: 'Operación',
        ddsj: 'Fecha del pedido',
        ddbh: 'Número de pedido',
        ckdd: 'Ver pedido',
        qrdd: 'Confirmar pedido',
        qxdd: 'Anular pedido',
        qrsh: 'Confirmar recepción de mercancías',
        zlyd: 'Volver a realizar un pedido',
        ddxq: 'Detalles del pedido',
        qm:'Nombre completo',
        dh:'Teléfono',
        wl:'Logística',
        w:'Ninguna',
        hwys:'Transporte'
    },
    logon:{
        dl:'Inicio de sesión',
        dl1:'',
        dl2:'Hacer un pedido rápidamente',
        dl3:'El acoplamiento directo tiene el mejor precio',
        dl4:'Respuesta eficiente',
        dl5:'Introduzca su dirección de correo electrónico',
        dl6:'Introduzca la contraseña',
        dl7:'Olvidar contraseña',
        dl8:'No hay cuenta? Registrarse como',
        dl9:'Introduzca el código de verificación',
        dl10:'Vuelva a introducir la contraseña',
        dl11:'Por favor, envíe el código de verificación',
        dl12:'Error de verificación',
        dl13:'Dos contraseñas introducidas no coinciden',
        dl14:'Enviar con éxito',
        dl15:'Recuperar',
        dl16:'Datos completos',
        dl17:'Introduzca sus apellidos',
        dl18:'Por favor, introduzca su nombre',
        dl19:'Introduzca su correo electrónico',
        dl20:'Por favor, introduzca el número de teléfono',
        dl21:'Por favor, introduzca el nombre de la empresa',
        dl22:'Por favor, introduzca el cargo',
        dl23:'En verificación',
        dl24:'Enviar de nuevo',
        dl25:'Enviar',
        dl26:'Recordar',
        dl27:'Fallo de verificación',
        dl28:'En verificación, espere pacientemente',
        d129:'Usuario',
        d130:'Contraseña',
        d131:'Nombre',
        d132:'Correo electrónico',
        d133:'Teléfono',
        d134:'Empresa',
        d135:'Cargo',
        d136:'Compradores'
    },
    xjd: {
        txxjd: 'Rellenar hoja de solicitud',
        xjdlb: 'Lista de hojas de solicitud',
        xjcp: 'Productos solicitados',
        pthf: 'Plataforma de respuesta',
        lxr: 'Contacto',
        lxrxx: 'Teléfono de contacto',
        srlxr: 'Introducir contacto',
        srlxrxx: 'Introduzca el número de teléfono de contacto',
        cpsl: 'Introduzca el nombre y la cantidad del producto solicitado',
        myg:'Fecha de llegada al puerto',
        cyg:'Puerto de embarque',
        mdg:'Puerto de destino',
        ysfs:'Condiciones de pago',
        mysysj:'Condiciones comerciales',
        qxz:'Seleccione',
        xzbz:'Seleccione embalaje',
        txsl:'Rellene la cantidad',
        xzhb:'Seleccione moneda',
        cgoods:'Seleccionar producto',
        scdd:'Generar pedido',
        addd:'Añadir',
        tishi:'Recordar',
        qx:'Cancelar',
        cg:'Determinar',
        tsxx:'Esta operación borrará definitivamente el registro de modificación ¿Desea continuar?',
        sccg:'Borrado con éxito',
        qxsc:'Cancelar borrado',
        ss:'Buscar',
        xjdbh:'Consultar Número de Hoja',
        cggsmc:'Nombre de la empresa',
        cggsdz:'Dirección de la empresa',
        cgyxm:'Nombre del comprador',
        cgydh:'Teléfono del comprador',
        yjdz:'Correo electrónico',
        dj:'Precio unitario',
        zongjia:'Total',
        dw:'Unidad',
        txdw:'Rellenar Unidad',
        cp:'Producto',
    }
}
export default Spanish