<template>
  <div id="main">
    <div ref="top111">
      <Header :num111="3" />
      <div style="background: #fff;overflow: hidden;">
        <div style="width:1200px;display: flex;justify-content: center;height: 400px;margin: 25px auto;">
          <div style="width: 18%;height: 100%;">

            <div style="width: 100%;">

              <div class="xxxxx">
                {{ $t('headQYCP') }}</div>
              <div class="tab1_menu_out">
                <div class="tab1_menu">
                  <div class="xxxx" @mouseenter="mouseenter(index, item)" v-for="(item, index) in goodsCate"
                    :key="'first_' + index" @click="fnToCatee(1)">
                    {{ $i18n.locale == 'en' ? item.cate_name_en
        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}
                  </div>
                </div>
                <div class="item_hover flex-wrap" v-show="showHover">
                  <div class="item_hover_left flex-con">
                    <div class="left1 pointer" v-for="(item, index) in secondGoodsCate" :key="'second_all_' + index">
                      <div class="second_txt" @click="fnToCate(2, item.cate_id, $i18n.locale == 'en' ? item.cate_name_en
        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp)">{{ $i18n.locale == 'en' ? item.cate_name_en
        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}</div>
                      <div class="font_small1 align-start">
                        <div v-for="(item1, index) in item.thirdGoodsCate" :key="'third_' + index"
                          class="third_txt pointer" @click.stop="fnToCate(3, item.cate_id, $i18n.locale == 'en' ? item.cate_name_en
        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp, item1.cate_id, $i18n.locale == 'en' ? item1.cate_name_en
        : $i18n.locale == 'es' ? item1.cate_name_es : item1.cate_name_tp)">{{ $i18n.locale == 'en' ? item1.cate_name_en
        : $i18n.locale == 'es' ? item1.cate_name_es : item1.cate_name_tp }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
         <div class="swiper-container" style="width:60%; border-radius: 0;height: 500px;" >
          <div id="swiper-container1" style="width:100%; border-radius: 0" >
            <div class="swiper-wrapper"  >
              <div class="swiper-slide pointer"   v-for="(item, index) in banner" :key="'banner_' + index"
                @click="fnGoodsDetails(item.banner_id)">
                <img :src="item.banner_image" style="height: 400px;width: auto;" />
               
              </div>
            </div>
            <div class="swiper-pagination" style=""></div>
          </div>
       
          <div class="flex-con align-center relative" style="margin-top: 20px;">
          <input type="text" name="" value=""  v-model="keyword" :placeholder="$t('headSSCP')" style="height: 42px;width: 524px;box-sizing:border-box ;padding: 0 0 0 6px;border: 2px solid #018ECF;border-radius: 2px 0 0 2px;
  z-index: 1;outline: none;" />
          
            <div style="width: 97px;height: 42px;background-color: #008DD1;border-radius: 0pt 4px 4px 0pt;color: #ffffff;line-height: 42px;display: flex;justify-content: center;" @click="fnToCatee(0)">
             <div style="width: 32px;height: 42px;" >
              <img :src="require('@/assets/search.png')" fit="cover" alt="" style="width: 20px;height: 20px;margin-top: 10px;"
             />
             </div>
              <div>{{ $t('xjd.ss') }}</div>
            </div>
        </div>
         </div>
          <div style="width: 18%;height: 415px;">
            <div class="" style="font-size: 16px;">{{ $t('mainTJCP') }}</div>
            <div class="top_right" @click="fnGoodsDetails(item.goods_id)" v-if="index < 5"
              v-for="(item, index) in recommendGoodsData" :key="'goods_' + item.goods_id + '_' + index">
              <div style="display: flex;">
                <div style="width: 60px;height: 60px;">
                  <el-image fit="contain" :src="item.goods_thumb" class="fourImg1"></el-image>
                </div>
                <div style="margin-left: 9px;">
                  <div class="top_right1">
                    {{ $i18n.locale == 'en' ? item.goods_name_en
        : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}
                  </div>
                  <div class="top_right2">
                    $ {{ item.goods_cu_price || '' }}
                  </div>


                </div>




              </div>

            </div>
          </div>
        </div>
        <div class="font-333-30-500 m_t80 m_b60 center" style="margin-top: 120px;margin-bottom: 36px;display: flex;justify-content: center;">
          <img src="../assets/l.png" alt="" style="width: 213px;height: 7px;margin-top: 18px
 ;margin-right: 24px;">
          {{ $t('mainCPFL') }}
          <img src="../assets/r.png" alt="" style="width: 213px;height: 7px;margin-top: 18px
 ;margin-left: 24px;">
        </div>
        <div class="" id="swiper-container3" style="width: 1200px;margin:1px  auto;">
          <div style="display: flex;width: 1200px;flex-wrap: wrap;justify-content:flex-start;gap: 25px;">
            <div class=" " v-for="(item, index) in allFirstGoodsCate" style="width: 294px;margin-right: 0px;width: 220px;"
              :key="'allFirstGoodsCate_' + index" @click="fnToCate(item.cate_id, $i18n.locale == 'en' ? item.cate_name_en
        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp)">
              <div class="product-fl">
                <div class="product-fl-in">
                  <div class="font-333-24-500 product-fl-title">
                    {{ $i18n.locale == 'en' ? item.cate_name_en
        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}
                  </div>
                  
                </div>
                <img :src="item.cate_image" class="product-fl-img" />
              </div>
            </div>
          </div>
        
        </div>
        <div class="w1200" style="margin-top: -60px;">
          <div v-for="(item, index) in goodsData" :key="'goods_' + item.cate_id + '_' + index">
            <div class="font-333-50-600  m_b36"  style="margin-top: 90px;">
              <span class="b-b-333">#{{ $i18n.locale == 'en' ? item.cate_name_en
        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}</span>
            </div>
            <div style="margin-top: -16px;font-size: 16px;color: #333333;width: 1200px;word-wrap: break-word;line-height: 22px;box-sizing: border-box;text-align: left;">
              {{ $i18n.locale == 'en' ? item.cate_desc_en
        : $i18n.locale == 'es' ? item.cate_desc_es : item.cate_desc_tp }}
                  </div>
            <div class="b-b-979797 p_b60" style="margin-top: 20px;">
              <div class="relative m_b36 tab_out1">
                <div class=" font-666-22 align-start nowrap">
                  <div class="pointer tab_in" :class="{ active: index == tabIndex }"
                    v-for="(item1, index1) in item.secondCate" :key="'tabData_' + index1" @click="fnTab(index1)"
                    :style="index == (item.secondCate.length - 1) ? 'margin-right:0' : ''">
                    {{ $i18n.locale == 'en' ? item1.cate_name_en
        : $i18n.locale == 'es' ? item1.cate_name_es : item1.cate_name_tp }}
                  </div>
                </div>
                <div class="white-meng"></div>
                
              </div>
              <div class="flex-wrap">
                <!-- <div  class="material-bg  " style="display: flex;align-items: center;flex-direction: column;position: relative;margin-right: 14px;">
                  <img :src="item.cate_image" alt="">
                  <div style="margin-top: 36px;color: #333333;font-size: 18px;font-weight: bold;box-sizing: border-box;width: 260px;text-align: left;">
                    {{ $i18n.locale == 'en' ? item.cate_name_en
        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}
                  </div>
                  <div style="margin-top: 20px;font-size: 16px;color: #333333;width: 260px;word-wrap: break-word;line-height: 22px;box-sizing: border-box;text-align: left;">
                    IntroductiontoPrimaryClassificationIntroductiontoPrimaryClassification（一句话介绍）
                  </div>
                  <img src="../assets/yj.png" alt="" style="width: 30px;height: 30px;position: absolute;bottom: 45px;right: 28px;">
                </div> -->
                <div class="flex-con two-out" style="width: 1200px;border-radius: 5px;background-color: #FAFCFF;box-sizing: border-box;padding: 10px;display: flex;flex-wrap: wrap;gap: 19px;">
                  <div class="two center pointer relative" v-for="(item2, index2) in item.goods"
                    :key="'goods1_' + item2.goods_id + '_' + index" v-if="index < 6"
                    @click="fnGoodsDetails(item2.goods_id)">
                    <!-- <input type="checkbox" name="collect" :checked="item2.isCollect == 1 ? true : false"
                      @click.stop='() => { }' disabled /> -->
                      <el-image style="width: 20px; height: 20px; margin-right: 16px" class="ppp" :src="item2.isCollect == 1
                    ? require('@/assets/star1.png')
                    : require('@/assets/star2.png')
                    " fit="cover"></el-image>
                    <div class="twoImg_out align-center">
                      <img :src="item2.goods_thumb" class="twoImg" alt="">
                    </div>
                    <div style="padding: 0 12px">
                      <div class="font-666-28 aui-ellipsis-2 h56" style="margin-top: -14px;">
                        {{ $i18n.locale == 'en' ? item2.goods_name_en
        : $i18n.locale == 'es' ? item2.goods_name_es : item2.goods_name_tp }}
                      </div>
                      <div class="center font-333-20-500" style="margin-top: 7px;line-height: 16px;font-weight: bold;" >
                        $ {{ item2.goods_cu_price || '' }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div v-show="index % 2 == 1" class="material-bg  m_l30" style="display: flex;align-items: center;flex-direction: column;">
                  <img :src="item.cate_image" alt="">
                     <div style="margin-top: 36px;color: #333333;font-size: 18px;font-weight: bold;">
                      {{ $i18n.locale == 'en' ? item.cate_name_en
        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}
                     </div>
                     <div style="margin-top: 20px;font-size: 16px;color: #333333;width: 260px;word-wrap: break-word;line-height: 22px;box-sizing: border-box;padding: 0 30px 0 30px;">
                    IntroductiontoPrimaryClassificationIntroductiontoPrimaryClassification（一句话介绍）
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="product-recommend p_t60 p_b60">
            <!-- <span class="color-008DD1">RECOMENDADOS</span> -->
            <div class="font-333-30-500 m_b14 center">{{ $t('mainTJCP') }}</div>
            <div class="font-666-20 center">{{ $t('mainTJCPYH') }}</div>
            <div class="four-out">
              <div class="four center relative"  v-for="(item, index) in recommendGoodsData"
                :key="'goods_' + item.goods_id + '_' + index">
                <!-- <input type="checkbox" name="collect" :checked="item.isCollect == 1 ? true : false"
                  @click.stop='() => { }' disabled /> -->
                  <el-image style="width: 20px; height: 20px; margin-right: 16px" class="ppp" :src="item.isCollect == 1
                    ? require('@/assets/star1.png')
                    : require('@/assets/star2.png')
                    " fit="cover"></el-image>
                <el-image fit="cover" :src="item.goods_thumb" class="fourImg"></el-image>
                <div style="padding: 0 20px">
                  <div class="font-666-28 aui-ellipsis-2 h56">
                    {{ $i18n.locale == 'en' ? item.goods_name_en
        : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}
                  </div>
                  <!-- <div class="center font-333-20-500">
                    <span class="through color-999 normal">$ {{ item.goods_price || '' }}</span>
                    $ {{ item.goods_cu_price || '' }}
                  </div> -->
                </div>
                <div class="center font-666-18 product-intro">
                  {{ $i18n.locale == 'en' ? item.goods_intro_en
        : $i18n.locale == 'es' ? item.goods_intro_es : item.goods_intro_tp }}
                </div>
                <div class="btn1 align-center m_t12 margin pointer" @click="fnGoodsDetails(item.goods_id)">{{
                  $t('mainCK')
                  }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- <div v-if="item.secondCate[numAll[index1]].goods.length > 0"> 
                   </div> 
               <div v-else>
                        <div style="text-align: center; margin: 195px auto 0">
                          <el-image style="
                              width: 160px;
                              height: 160px;
                              margin-bottom: 16px;
                            " :src="require('@/assets/quesheng/no_content.png')" fit="cover"></el-image>
                          <div class="font-666-14">商品完善中~</div>
                        </div>
                      </div> -->
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Swiper from 'swiper/bundle'
import { bannerList, firstGoodsCate, indexGoodsData, recommendGoods, goodsCateList } from '../api'
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      banner: [],
      allFirstGoodsCate: [],
      goodsData: [],
      recommendGoodsData: [],
      tabIndex: 0,
      first_cate_id: "",
      keyword:'',
      GoodsCate:[],
      secondGoodsCate:[],
      showHover:false,
      

    }
  },

  mounted() {
    this.initCate();
    this.initLB();
    this.initFirstGoodsCate();
    this.initGoodsData();
    this.initRecommendGoods();
    new Swiper('#swiper-container1', {
      autoplay: true,
      observer: true,
     
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
   
    
  
  },
  methods: {
    fnToCate(cate_id1, cate_id1_name) {
      this.$router.push({
        name: 'cate',
        params: {
          id1: cate_id1,
          id1_name: cate_id1_name
        }
      })
    },
    initCate() {
      let params = {}
      goodsCateList(params).then((data) => {
        if (data.status == 200) {
          this.goodsCate = data.msg
          console.log(data.msg);
          console.log(787878);
          
          
          this.secondGoodsCate = data.msg[0].secondGoodsCate
        }
      })
    },
    initRecommendGoods() {
      let params = {}
      recommendGoods(params).then((data) => {
        if (data.status == 200) {
          this.recommendGoodsData = data.msg
        }
      })
    },
    initGoodsData() {
      let params = {}
      indexGoodsData(params).then((data) => {
        if (data.status == 200) {
          this.goodsData = data.msg
         

        }
      })
    },
    initFirstGoodsCate() {
      let params = {
        type: 1
      }
      firstGoodsCate(params).then((data) => {
        if (data.status == 200) {
          this.allFirstGoodsCate = data.msg
        }
      })
    },
    initLB() {
      let params = {}
      bannerList(params).then((data) => {
        if (data.status == 200) {
          this.banner = data.msg
      
          
          
        }
      })
    },
    fnToCatee(type, cate_id1, cate_id1_name, cate_id2, cate_id2_name) {//type==1 cate_id1为一级分类id,type==2 cate_id1为二级分类id,type==3 cate_id1为二级分类id cate_id2为三级分类id
      // console.log(type + '||' + this.$router.history);
      if (type == 0) {
        if (this.$router.history.current.name == 'cate') {
          this.$bus.$emit('keyword', this.keyword)
        } else {
          this.$router.push({
            path: 'cate',
            query: {
              keyword: this.keyword
            }
          })
        }
      } else {
        if (this.$router.history.current.name == 'cate') {
          this.$bus.$emit('cate', {
            id1: this.first_cate_id,
            id2: cate_id1,
            id3: cate_id2,
            id1_name: this.first_cate_name,
            id2_name: cate_id1_name,
            id3_name: cate_id2_name
          })
        } else {
          this.$router.push({
            name: 'cate',
            params: {
              id1: this.first_cate_id,
              id2: cate_id1,
              id3: cate_id2,
              id1_name: this.first_cate_name,
              id2_name: cate_id1_name,
              id3_name: cate_id2_name
            }
          })
        }
      }
    },

    initCateAlreadyRead() {
      let params = {}
      alreadyReadNumber(params).then((data) => {
        if (data.status == 200) {
          this.xjdNum = data.msg
        }
      })
    },
    exit() {
      localStorage.setItem('allUserMr', '')
      this.$router.push({ name: 'login' })
    },
    initCate() {
      let params = {}
      goodsCateList(params).then((data) => {
        if (data.status == 200) {
          this.goodsCate = data.msg
          this.secondGoodsCate = data.msg[0].secondGoodsCate
        }
      })
    },
    handleCommand(command) {
      this.language = command == 'en' ? 'English' : command == 'es' ? 'Español' : 'Português';
      this.lan = command;
      this.$i18n.locale = command;
      localStorage.setItem('lang', command)
    },
    handleCommand1(command) {
      switch (command) {
        case 'jbxx':
          this.fnCenter()
          break;
        case 'dzgl':
          this.$router.push({
            name: 'myAddress'
          })
          break;
        case 'wdsc':
          this.$router.push({
            name: 'collect'
          })
          break;
        case 'tczh':
          this.exit()
          break;
        default:
          this.$router.push({
            name: 'orderCenter',
            params: {
              id: command
            },
          })
          this.$bus.$emit("orderType", command);
          break;
      }
    },
    fnCar() {
      this.$router.push({
        name: 'car'
      })
    },
    fnCenter() {
      this.$router.push({
        name: 'myInfo'
      })
    },
    fnCate(type) {
      if (type == 3) {
        this.toMain()
      }
      if (type == 4) {
        this.$router.push({
          name: 'inquiry'
        })
      }
    },
    fnCarNum() {
      let params = {}
      shopCarNumber(params).then((data) => {
        if (data.status == 200) {
          this.shopCarNumber111 = data.msg
        }
      })
    },
    mouseenter(index, item) {
      this.isHover = index;
      this.first_cate_id = item.cate_id;
      this.first_cate_name = this.lan == 'en' ? item.cate_name_en
        : this.lan == 'es' ? item.cate_name_es : item.cate_name_tp
      this.secondGoodsCate = this.goodsCate[index].secondGoodsCate
    },
    mouseleave(index, item) {
      this.isHover = -1;
    },
    mouseenterOut() {
      this.showHover = true;
    },
    mouseleaveOut() {
      this.showHover = false;
    },
    mouseenterOutFl() {
      this.cateShow = true;
      this.initCate()
    },
    mouseleaveOutFl() {
      this.cateShow = false;
    },
  }
}
</script>

<style scoped>

.btn1 {
  font-size: 18px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 36px;
  height: 36px;
  background: #008DD1;
  border-radius: 36px;
  width: 82%;
  transition: all 0.3s;
  position: absolute;
  bottom: 18px;
  left: 0;
  right: 0;

}

.four:hover .product-intro {
  display: block;
}

.four:hover .fourImg {
  display: none;
}

.product-intro {
  /* height: 40%;
  overflow-y: scroll; */
  display: none;
  margin-top: 40px;
  padding: 0 20px;
  transition: all 0.3s;
}

/* .product-intro::-webkit-scrollbar {
  display: none;
} */

.collect {
  display: none;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 1;
}

/* input[type='checkbox'] {
  position: absolute;
  right: 8px;
  top: 8px;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #018ECF;
  border-radius: 2px 0 0 2px;
  z-index: 1;
} */

/* input[type='checkbox']:checked {
  border: 2px solid #018ECF;
  background: url('@/assets/checked1.png') no-repeat center center;
  background-size: 18px 18px;
} */

.two {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 318px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding-top: 36px;
  transition: all 0.3s;
}

.twoImg_out {
  display: block;
  width: 220px;
  height: 220px;
  margin: -34px auto 20px;
  position: relative;
}

.twoImg_out .twoImg {
  object-fit: cover;
  display: block;
  width:100%;
  height: fit-content;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.two-out .two {
  margin-bottom: 6px;
}

/* .two-out .two:nth-child(4n+2) {
  margin: 0 14px;
}
.two-out .two:nth-child(4n+4) {
  margin: 0 0 0 14px ;
} */

.four-out {
  display: flex;
  flex-wrap: wrap;
}

.fourImg {
  display: block;
  width: 215px;
  height: 215px;
  margin: 0 auto 16px;
  transition: all 0.3s;
  

}

.fourImg1 {
  display: block;
  width: 100%;
  height: 100%;

  transition: all 0.3s;
}

.four-out .four {
 
  margin-top: 38px;
  position: relative;
  display: inline-block;
  width: 215px;
  height: 383px;
  background: #F8F8F8;
  box-sizing: border-box;
  /* padding-top: 30px; */
  transition: all 0.3s;
}

.four-out .four:hover {
  padding-top: 74px;
}

.four-out .four:nth-child(5n+2) {
  margin: 40px 30px 0 30px;
}
.four-out .four:nth-child(5n+4) {
  margin: 40px 30px 0 30px;
}

.material-bg {
  width: 319px;
  height: 600px;
  background-color: #F9FCFF;
  background-size: 100% 806px;
  transition: all 0.3s;
}

.material-bg:hover,
.two:hover {
  box-shadow: 0px 0px 20px 0px rgba(42, 118, 168, 0.2);
}

.material-bg img {
  width: 319px;
  height: 319px;
}

.white-meng {
  width: 68px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.15) 100%);
  /* background: url('@/assets/swipe_jt_r2.png') no-repeat center center, linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.15) 100%); */
  /* background-color: #000;
  background-image: url('@/assets/swipe_jt_r2.png');
  background-repeat: no-repeat;
  background-position: center center; */
  /* background-repeat: no-repeat;
  background-size: 22px 22px; */
}



/* .tab_out::-webkit-scrollbar {
  display: none;
  transition: all 2s;
} */

/* 
.tab_out1:hover .tab_out::-webkit-scrollbar {
  display: block;
} */

/* .tab_out::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.tab_out::-webkit-scrollbar-corner {
  background-color: transparent;
}

.tab_out::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.2);
} */

.tab_out {
  overflow-x: scroll;
}

.tab_out>div {
  margin-right: 50px;
}

.tab_out .active,
.tab_in:hover {
  font-weight: 500;
  color: #018ECF;
}

.product-fl .product-fl-img {
  width: 220px;
  height: 220px;
  position: absolute;
 
  top: 0px;
  object-fit: cover;

}

.product-fl .product-fl-jt_right {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 16px;
  left: 20px;
}

.product-fl {
  width: 220px;
  height: 300px;
  position: relative;
  
  box-sizing: border-box;
}

.product-fl-in {
  box-sizing: border-box;
  width: 220px;
  height: 300px;
  background: #F2F9FC;
  border-radius: 4px;
  padding-top: 40px;
  transition: all 0.3s;
  position: relative;
  opacity: 1;
  
}

.product-fl:hover .product-fl-in {
  background: #018ECF;
}

.top_right {
  width: 100%;
  margin-top: 12px;
  background-color: #F8F8F8;
  height: 80px;
  box-sizing: border-box;
  padding: 12px;
}

.top_right:hover {
  background: #018ECF;
  cursor: pointer;
}

.top_right1 {
  color: #666666;
  font-size: 14px;
  width: 133px;
  height: 28px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top_right2 {
  margin-top: 10px;
  color: #008DD1;
  font-size: 16px;
  height: 25px;
}

.top_right:hover .top_right1 {
  color: #FFFFFF;
}

.top_right:hover .top_right2 {
  color: #FFFFFF;
}

.product-fl:hover .product-fl-title {
  color: #FFFFFF;
}



.product-fl-title {
  /* position: absolute;
  bottom: 70px;
  left: 20px; */
  margin-top: 200px;
  line-height: 25px;
  transition: all 0.3s;
  font-size: 20px;
  text-align: center;
  box-sizing: border-box;
  padding:0 10px 0 10px ;
  overflow: hidden;
  white-space: wrap; 
  text-overflow: ellipsis; 
}
</style>

<style scoped>
@import url('swiper/swiper-bundle.css');

.swiper-container {

  overflow: hidden;
}


.swiper-slide img {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: 110px;
  text-align: right;
  padding-right: 60px;
  box-sizing: border-box;
}

.swiper-container2 {
  
  height: 350px;
  background: url('@/assets/logo_1.png') no-repeat 100% center;
  background-size: 100% 400px;
  background-size:contain;
  
}

.swiper-container2 .swiper-slide {
  
  position: relative;
  height: 350px;
}


.swiper-button-prev:after {
  display: none;
}

.swiper-button-next:after {
  display: none;
}

.swiper-button-prev {
  width: 60px;
  height: 60px;
  background: url('../assets/swipe_jt_l1.png') no-repeat center center;
  background-size: 100px 100px;
  border-radius: 50%;
  top: calc(100%/2 - 80px);
}

.swiper-button-next {
  width: 60px;
  height: 60px;
  background: url('../assets/swipe_jt_r1.png') no-repeat center center;
  background-size: 100px 100px;
  top: calc(100%/2 - 80px);
  border-radius: 50%;
  
}

::v-deep .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background: #ffffff;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
}

::v-deep .swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  background: #008DD1;
  display: inline-block;
  border-radius: 50%;
}

::v-deep .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin-right: 0;
  margin-left: 15px;
}

.xxxx {
  height: 34px;
  
  text-align: center;
  line-height: 34px;
  color: #333333;
  font-size: 16px;

  border: 1px solid #F8F8F8;



}

.xxxx:hover {
 
  color:#2A76A8;
  cursor: pointer;
}

.xxxxx {
  width: 215px;
  height: 38px;

  background-color: #008DD1;
  border-radius: 4px 4px 0px 0px;
  text-align: center;
  line-height: 38px;
  color: #FFFFFF;
  font-size: 18px;
}
.font-666-28{
  font-size: 18px;
}
.font-333-20-500{
  font-size: 16px;
  margin-top: 6px;
}
.ppp{
  position: absolute;
    right: -4px;
    top: 8px;
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    
   
    z-index: 1;
}
</style>
