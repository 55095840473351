<template>
  <div class="bg-fff">
    <Header />
    <div class="flex-wrap box margin">
      <div class="left1">
        <MyInfo @update="initDataShua" :num="type" />
      </div>
      <div class="flex-con four-box">
        <div class="box1">
          <div class="font-333-20-500">{{ type == 0 ? $t('headGRZXObj.qbdd') : type == 1 ?
                    $t('headGRZXObj.dqr') :
                    type == 2 ?
                        $t('headGRZXObj.dfk') : type == 3 ? $t('headGRZXObj.dfh') : type == 4 ?
                            $t('headGRZXObj.dsh') : $t('headGRZXObj.ywc') }}</div>
        </div>
        <div class="flex-wrap align-between center box2 font-666-18">
          <div class="flex-con left">{{ $t('orderObj.ddxx') }}</div>
          <div style="width:100px;">{{ $t('orderObj.gg') }}</div>
          <div style="width:150px;">{{ $t('carObj.je') }}</div>
          <div style="width:130px;">{{ $t('orderObj.zt') }}</div>
          <div style="width:160px;">{{ $t('orderObj.cz') }}</div>
        </div>
        <div class="box3">
          <div class="order-item m_t12" v-for="(item, index) in data" :key="item.order_id">
            <div class="box4">
              <div class="align-between font-666-18">
                <div>{{ $t('orderObj.ddsj') }}</div>
                <div>{{ $t('orderObj.ddbh') }}. <span class="font-333-18">#{{ item.order_number
                                        }}</span>
                </div>
              </div>
              <div class="align-between font-333-18">
                <div>{{ item.order_time }}</div>
                <div class="font-018ECF-18 pointer" @click="fnDetail(item.order_id)">{{
                    $t('orderObj.ckdd') }}</div>
              </div>
            </div>
            <div class="box5 flex-wrap align-between center">
              <div class="flex-con left">
                <div class="flex-wrap m_t12" v-for="(item, index) in item.orderGoods" :key="item.of_id">
                  <div class="flex-con align-start">
                    <img :src="item.goods_thumb" alt="">
                    <div class="font-333-18-500 m_r8">{{ $i18n.locale == 'en' ?
                    item.goods_name_en
                    : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
                  </div>
                  <div class="font-333-18 align-center" style="min-width:100px;width:100px;">
                    {{ item.packaging_name }} <br> {{ item.spec_value }}
                  </div>
                </div>
              </div>
              <div class="font-333-18" style="width:150px;">
                $ {{ item.order_total_price }}
              </div>
              <div class="font-333-18 p_lr10 border-box" style="width:130px;">
                {{ item.order_status == 1 ? $t('headGRZXObj.dqr') :
                    item.order_status == 2 ?
                        $t('headGRZXObj.dfk') : item.order_status == 3 ? $t('headGRZXObj.dfh') :
                            item.order_status == 4 ?
                                $t('headGRZXObj.dsh') : item.order_status == 5 ? $t('headGRZXObj.ywc') :
                                    $t('headGRZXObj.yqx') }}
              </div>
              <div style="width:160px;" class="btnOut">
                <div class="btn align-center pointer" v-show="item.order_status == 1" @click.stop="fnSure(1,item.order_id)">{{
                    $t('orderObj.qrdd') }}</div>
                <div class="btn align-center pointer" v-show="item.order_status == 1 || item.order_status == 2" @click.stop="fnSure(2,item.order_id)">{{ $t('orderObj.qxdd')
                                    }}</div>
                <div class="btn align-center pointer" v-show="item.order_status == 4" @click.stop="fnSure(3,item.order_id)">{{
                    $t('orderObj.qrsh') }}</div>
                <div class="btn align-center pointer" v-show="item.order_status == 5 && item.orderGoods.length == 1" @click.stop="fnGoodsDetails(item.orderGoods[0].of_goods_id)">{{ $t('orderObj.zlyd')
                                    }}</div>
              </div>
            </div>
          </div>
          <div class="align-center m_t12">
            <Page :limit="limit" :totalNum="total" @onPage="changePage" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" append-to-body >
            <span>{{ts}}</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="submit" size="mini">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import Page from '@/components/Page.vue'
import { limit, orderList, sureOrder, overOrder, cancelOrder } from '../api'
export default {
  components: {
    Header,
    Footer,
    MyInfo,
    Page
  },
  data() {
    return {
      page: 1,
      limit: limit,
      total: 0,
      type: 0,
      data: [],
      ts:'',
      dialogVisible:false,
      orderId:'',
      where:0
    }
  },
  mounted() {
    let can = this.$route.params.id
    this.type = can
    this.initOrderList()
  },
  methods: {
    initOrderList() {
      let params = {
        status: this.type,
        page: this.page
      }
      orderList(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.total = data.count
        }
      })
    },
    fnDetail(idd) {
      this.$router.push({
        name: 'orderDetails',
        params: {
          order_id: idd
        }
      })
    },
    fnSure(type,id){
        this.dialogVisible=true;
        this.where=type;
        if(this.where==1){
            this.ts="是否确认订单"
        }else if(this.where==2){
            this.ts="是否取消订单"
        }else{
            this.ts="是否确认收货"
        }
        this.orderId=id;
    },
    submit(){
        if(this.where==1){
            this.fnSureOrder(this.orderId);
        }else if(this.where==2){
            this.fnCancleOrder(this.orderId);
        }else{
            this.fnSureSh(this.orderId) 
        }
    },
    fnSureOrder(idd) {
      let params = {
        order_id: idd
      }
      sureOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.dialogVisible=false;
          this.page = 1
          this.initOrderList()
        }
      })
    },
    fnCancleOrder(idd) {
      let params = {
        order_id: idd
      }
      cancelOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.dialogVisible=false;
          this.page = 1
          this.initOrderList()
        }
      })
    },
    fnSureSh(idd) {
      let params = {
        order_id: idd
      }
      overOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.page = 1
          this.initOrderList()
        }
      })
    },
    initDataShua(idd) {
      // console.log(idd);
      this.type = idd
      this.page = 1
      this.initOrderList()
    },
    changePage(childrenData) {
      this.page = childrenData;
      this.initOrderList()
    },
    handleClose(done) {
        done();
    },
  }

}
</script>


<style scoped>
.btn {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
  width: 160px;
  height: 38px;
  background: #008dd1;
  border-radius: 4px;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 12px;
}

.btnOut > .btn:first-child {
  margin-top: 12px;
}

.box5 {
  padding: 0 12px 12px 16px;
}

.box5 img {
  width: 100px;
  height: 100px;
  min-width: 100px;
  object-fit: cover;
  margin-right: 8px;
}

.box4 {
  padding: 12px 16px;
  background: #f4f4f4;
}

.order-item {
  border: 1px solid #f2f2f2;
}

.box1 {
  padding: 16px 16px 12px;
}

.box2 {
  padding: 0 28px 12px 16px;
  border-bottom: 1px solid #979797;
}

.box3 {
  padding: 0 16px 12px;
}

.four-box {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.four-out {
  display: flex;
  flex-wrap: wrap;
}

.fourImg {
  display: block;
  width: 158px;
  height: 158px;
  margin: 0 auto 6px;
  transition: all 0.3s;
}

.four-out .four {
  margin-right: 12px;
  margin-top: 12px;
  position: relative;
  display: inline-block;
  width: calc((100% - 24px) / 3);
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 6px 0 12px;
  transition: all 0.3s;
}

.four-out .four:hover {
  padding-top: 30px;
}

.four-out .four:nth-child(3n) {
  margin-right: 0;
}

.btn1 {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 25px;
  height: 34px;
  background: #008dd1;
  border-radius: 17px;
  width: 208px;
  transition: all 0.3s;
}

.four:hover .product-intro {
  display: block;
}

.four:hover .fourImg,
.four:hover .btn1 {
  display: none;
}

.product-intro {
  height: 40%;
  /* overflow-y: scroll; */
  display: none;
  margin-top: 20px;
  padding: 0 20px;
  transition: all 0.3s;
}

.product-intro::-webkit-scrollbar {
  display: none;
}

.collect {
  display: none;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 1;
}

.left1 {
  width: 232px;
  margin-right: 30px;
}

.box {
  padding: 30px 0;
  width: 1200px;
}
</style>